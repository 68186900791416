import React, {useEffect, useState, useRef } from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import ModuleWrapper from '../../components/ModuleWrapper';
import Slide from './Slide';
import { NoopDebugEngine } from "styletron-react";
import { clearConfigCache } from "prettier";

const Wrapper = styled("div", {
    paddingBottom: "0rem",
    [media.min.tablet]: {
        paddingBottom: "0rem"
    }
})

const Slides = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "3rem 1rem 2.5rem 1rem",
    overflowX: "auto",
    "-webkit-overflow-scrolling": "touch",
    "::-webkit-scrollbar": {
        backgroundColor: "#fff",
        color: "#fff",
        backgroundColor: "transparent"
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    [media.min.tablet]: {
        padding: "0rem 2rem",
        scrollbarWidth: "auto",
        msOverflowStyle: "auto"
    }
});

const SlideCounter = styled("p", {
    display: "none",
    [media.min.tablet]: {
        display: "block",
        padding: "2.5rem 0 5rem 2rem"
    }
});

const SlideBarWrapper = styled("div", props => ({
    width: "calc(100% - 32px)",
    display: "flex",
    position: "absolute",
    left: "16px",
    [media.min.tablet]: {
        display: "none"
    }
}));

const SlideBar = styled("div", {
    width: "100%",
    border: "1px solid #DBDBDB"
});

const SlideBarIndicator = styled("div", props => ({
    position: "absolute",
    width: "24.7813411%",
    border: "1px solid #000000",
    bottom: "0",
    left: props.$position
}));

const Slider = ({ blok }) => {
    const [offset, setOffset] = useState(0);
    const slideRef = useRef();
    const [elementWidth, setElementWidth] = useState(undefined);

    useEffect(() => {
        function handleScroll(){
            let share = (((slideRef.current.scrollLeft / (slideRef.current.scrollWidth - slideRef.current.offsetWidth)) * 100));
            let scrollPer = (share * 0.755) + "%"
            setOffset(scrollPer);
        }

        function handleResize(){
            setElementWidth(slideRef.current.offsetWidth);
        }

        slideRef.current.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        slideRef.current.addEventListener('mousedown', e => {
            slideRef.current.isDown = true;
            slideRef.current.classList.add('active');
            slideRef.current.startX = e.pageX - slideRef.current.offsetLeft;
            slideRef.current.scrollLeftValue = slideRef.current.scrollLeft;
        });
        slideRef.current.addEventListener('mouseleave', () => {
            slideRef.current.isDown = false;
            slideRef.current.classList.remove('active');
        });
        slideRef.current.addEventListener('mouseup', () => {
            slideRef.current.isDown = false;
            slideRef.current.classList.remove('active');
        });
        slideRef.current.addEventListener('mousemove', e => {
            if(!slideRef.current.isDown){
                return;
            }
            e.preventDefault();
            const x = e.pageX - slideRef.current.offsetLeft;
            const walk = x - slideRef.current.startX;
            slideRef.current.scrollLeft = slideRef.current.scrollLeftValue - walk;
        });

        
        return () => {
            window.removeEventListener("resize", handleResize);
            
            if (slideRef && slideRef.current) {
                slideRef.current.removeEventListener("scroll", handleScroll);
            }
        }
    },[]);

    let slideTrack = (blok.slides.length < 10) ? '01-0' : "01-";

    return (
        <ModuleWrapper>
            <Wrapper>
                <Slides ref={slideRef}>
                    {blok.slides.map((blokItem, index) =>
                        React.createElement(Slide, {
                            key: blokItem._uid,
                            blok: blokItem,
                            index: index,
                            length: blok.slides.length,
                        })
                    )}
                </Slides>
                <SlideCounter>{slideTrack + blok.slides.length}</SlideCounter>
                <SlideBarWrapper $width={elementWidth}>
                    <SlideBar>
                        <SlideBarIndicator $position={offset}></SlideBarIndicator>
                    </SlideBar>
                </SlideBarWrapper>
            </Wrapper>
        </ModuleWrapper>
    )
};

export default Slider;