type FontSize = { [key: string]: string };

const fontSizes: FontSize = {
    0: '0rem',
    9: '0.9rem',
    10: '1rem',
    11: '1.1rem',
    12: '1.2rem',
    13: '1.3rem',
    14: '1.4rem',
    16: '1.6rem',
    18: '1.8rem',
    20: '2rem',
    22: '2.2rem',
    24: '2.4rem',
    28: '2.8rem',
    32: '3.2rem',
    40: '4rem',
    48: '4.8rem',
    56: '5.6rem',
    64: '6.4rem',
    72: '7.2rem',
    80: '8rem',
};

export default fontSizes;