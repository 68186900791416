import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import BlokImage from "components/BlokImage";
import Fade from 'react-reveal/Fade';

const Item = styled("div", {
    // marginBottom: "4.5rem",
    marginTop: "2.5rem",
    position: "relative",

    [media.min.tablet]: {
        padding: "0p",
    },
  
    [media.min.laptop]: {
        padding: "0px",
    },
});

const Header = styled("div", {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "0.75rem"
});

const Title = styled("h3", {

    fontSize: "3rem",

    [media.min.laptop]: {
        fontSize: "6.375rem",
        lineHeight: "6.6875rem",
        display: "inline-block"
    }
});

const Addresses = styled("div", props => ({
    fontSize: "18px",
    display: "none",
    lineHeight: "22px",
    padding: "0 0 19px 22px",

    [media.min.tablet]: {
        display: "inline-block",
        paddingBottom: "0"
    },
    [media.min.laptop]: {
        display: "inline-block",
        paddingBottom: props.$index === 0 ? "14px" : "14px"
    }
}));

const AddressesMobile = styled("div", {
    fontSize: "18px",
    display: "inline-block",
    paddingTop: "0.5rem",

    [media.min.tablet]: {
        display: "none"
    }
})

const AddressLine = styled("div", {
    
});


const Images = styled("div", props => ({
    display: props.$length > 1 ? "flex" : "block",
    // margin: props.$length > 1 ? "0 -2rem" : "0",
    width: "100%",
    [media.min.tablet]: {
        width: "auto"
    }
}));


const Image = styled("div", props => ({
    width: props.$length > 1 ? "50%" : "100%", 
    // padding: props.$length > 1 ? "0 2rem" : "0",
    paddingRight: props.$length > 1 && (props.$index === 0) ? "1rem" : "0",
    [media.min.tablet]: {
        paddingRight: props.$length > 1 && (props.$index === 0) ? "2rem" : "0",
    }
}));

const Address = ({ blok, index }) => {   

    const images = blok.image;

    return (
        <Item>
            <Header>
                <Title>{blok.title}</Title>
                <Addresses $index={index}>
                    <AddressLine>{blok.street}</AddressLine>
                    <AddressLine>{blok.zip} {blok.city}</AddressLine>
                    <AddressLine>{blok.country}</AddressLine>
                </Addresses>
            </Header>
            {images && images.length === 1 && 
                <Images $length={images.length}>
                    {blok.image.map((blokImg, index) =>
                    <Fade>
                            <Image $length={images.length} $index={index}>
                            <BlokImage
                                wrapperStyle={{
                                    height: "100%",
                                    width: "100%",
                                    position: "relative",
                                    filter: "brightness(0.77)",
                                    top: "0",
                                    left: "0",
                                }}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                alt={blokImg.title}
                                fluid={{}}
                                blok={blokImg.filename}
                            />
                                <AddressesMobile>
                                    <AddressLine>{blok.street}</AddressLine>
                                    <AddressLine>{blok.zip} {blok.city}</AddressLine>
                                    <AddressLine>{blok.country}</AddressLine>
                                </AddressesMobile>
                        </Image>
                        </Fade>
                    )}
            </Images>}
            {images && images.length > 1 &&
                <Images $length={images.length}>
                    {blok.image.map((blokImg, index) =>
                        <Image $length={images.length} $index={index}>
                            <BlokImage
                                wrapperStyle={{
                                    height: index === 0 ? "55%" : "100%",
                                    width: "100%",
                                    position: "relative",
                                    filter: "brightness(0.77)",
                                    top: "0",
                                    left: "0",
                                }}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                alt={blokImg.title}
                                fluid={{}}
                                blok={blokImg.filename}
                            />
                            {index === 0 &&
                                <AddressesMobile>
                                    <AddressLine>{blok.street}</AddressLine>
                                    <AddressLine>{blok.zip} {blok.city}</AddressLine>
                                    <AddressLine>{blok.country}</AddressLine>
                                </AddressesMobile>
                            }
                        </Image>
                    )}
            </Images>}
        </Item>
    )
}   

export default Address;