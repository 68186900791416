import React from "react";
import { styled } from 'libs/styletron';
import Fade from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown'
import SbEditable from "storyblok-react";
import { media } from "config/theme";
import Column from "components/layouts/Column";
import DefaultWrapper from "components/layouts/DefaultWrapper";


const Title = styled("h2", {
  fontSize: "1.875rem",
  marginBottom: "5rem",

  [media.min.laptop]: {
    fontSize: "3rem",
    lineHeight: "3rem",
    marginBottom: "1.25rem",
  }
});

const Text = styled("div", props => ({
  fontSize: "1rem",
  lineHeight: "1.875rem",

  [media.min.tablet]: {
  },
  
  [media.min.laptop]: {
    fontSize: "1.125rem",
    lineHeight: "1.5rem"
  },
}));


const TextBlock = ({ blok }) => (
  <SbEditable content={blok}>
    <Fade>
      <DefaultWrapper layout="horizontal" columns={true} border="none">
        <Column space="4">
          <Title>{blok.title}</Title>
        </Column>
        <Column>
          <Text>
            <ReactMarkdown className="markdown" children={blok.text}>
              
            </ReactMarkdown>
          </Text>
        </Column>
      </DefaultWrapper>
    </Fade>
  </SbEditable>

);

export default TextBlock;