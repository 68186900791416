import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "../../config/theme";
import SbEditable from 'storyblok-react';



const Item = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 2rem",
    [media.min.tablet]: {
        // padding: "0 5.66rem"
    }
});


const Title = styled("h2", props => ({
    fontSize: "1.875rem",
    lineHeight: "2.125rem",

    [media.min.tablet]: {
        fontSize: "6.375rem",
        lineHeight: "6.75rem",
        display: "flex",
        alignItems: "flex-end",
    },

    color: colors.grey.light,
    transition: "all .3s ease-in-out"
}));

const Description = styled("p", props => ({
    fontSize: "1rem",
    lineHeight: "1.25rem",

    [media.min.tablet]: {
        fontSize: "1.125rem",
        lineHeight: "1.5rem"
    },

    marginBottom: "2.5rem",
    color: colors.grey.light,
    transition: "color .3s ease-in-out"
}));

const TextWrapper = styled("div", props => ({
    [media.min.tablet]: {
        width: "317px",
        height: "228px"
    }
}));



const Slide = ({ blok }) => {
    return (
        <SbEditable content={blok}>
            <Item>
                <TextWrapper>
                    <Title>{blok.Title}</Title>
                    <Description>{blok.Text}</Description>
                </TextWrapper>
            </Item>
        </SbEditable>
    )
}   

export default Slide;