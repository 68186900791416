import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Column from "components/layouts/Column";
import DefaultWrapper from "components/layouts/DefaultWrapper";

const Title = styled("h2", {
  fontSize: "2rem",
  marginBottom: "1.25rem",

  [media.min.tablet]: {
    fontSize: "2rem",
  },

  [media.min.laptop]: {
    fontSize: "1.875rem"
  },
});

const Text = styled("p", props => ({
  fontSize: "1rem",
  lineHeight: "1.25rem",

  [media.min.tablet]: {
  },
  
  [media.min.desktop]: { 
    fontSize: "1.125rem",
    lineHeight: "1.5rem" 
  }
}));


const TitleText = ({ blok }) => (

  <SbEditable content={blok}>
    <DefaultWrapper layout="horizontal">
      <Column space="4">
        <Title>{blok.title}</Title>
      </Column>
      <Column>
        <Text>{blok.text}</Text>
      </Column>
    </DefaultWrapper>
  </SbEditable>

);

export default TitleText;