import React from "react";
import ReactMarkdown from 'react-markdown'
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import ToTop from "../../buttons/ScrollToTop";
import Newsletter from "modules/Newsletter/NewsletterFooter";

const FooterWrap = styled('footer', {
  position: "relative",
  fontSize: "1rem",
  margin: "8rem 0 0rem 0",
  paddingBottom: "4rem",
  lineHeight: "1.25rem",

  [media.min.laptop]: {
    margin: "8rem 1rem 0rem 1rem",
    paddingBottom: "3.375rem"
  },
});

const UpperFooter = styled('div', {

  [media.min.laptop]: {
    display: "flex",
    alignItems: "flex-start",
  },
});

const LowerFooter = styled('div', {
  marginLeft: "1rem",
  marginRight: "1rem",

  [media.min.laptop]: {
    marginLeft: "25%",
    marginRight: "unset",
    width: "25%",
  }

});

const Item = styled('div', {
  marginBottom: "2.5rem",
  padding: "0 1rem",

  [media.min.tablet]: {
    width: "25%"
  },

  [media.min.laptop]: {
    marginBottom: "0",
  }
});

const Title = styled('span', {
  letterSpacing: "1rem"
});


const Footer = ({ blok }) => blok ? (
    <FooterWrap>
      <UpperFooter className="footer-wrapper">
        <Item>
          <Title>{blok.title}</Title>
        </Item>
        <Item>
          <ReactMarkdown>{blok.info}</ReactMarkdown>
        </Item>
        <Item>
          <ReactMarkdown>{blok.address1}</ReactMarkdown>
        </Item>
        <Item>
          <ReactMarkdown>{blok.address2}</ReactMarkdown>
        </Item>
      </UpperFooter>
      <LowerFooter>
        <Newsletter />
      </LowerFooter>
      <ToTop />
    </FooterWrap>
) : null;

export default Footer;