import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "../../config/theme";
import SbEditable from 'storyblok-react';



const Item = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingRight: "2rem",

});

const Title = styled("h2", props => ({
    fontSize: "6.375rem",
    lineHeight: "6.75rem",
    transition: "all .3s ease-in-out"
}));

const Description = styled("p", props => ({
    fontSize: "1rem",
    lineHeight: "1.25rem",
    height: "140px",
    width: "270px",
    transition: "color .3s ease-in-out"
}));

const Slide = ({ blok }) => (
    <SbEditable content={blok}>
        <Item>
            <Title>{blok.title}</Title>
            <Description>{blok.text}</Description>
        </Item>
    </SbEditable>
);

export default Slide;