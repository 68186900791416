import React from "react";
import { styled } from 'libs/styletron';
import regExp from 'helpers/regExp';
import { colors, media } from "config/theme";
import RadioButton from 'components/RadioButton';


const Wrapper = styled("div", {
    marginTop: "2rem",

    [media.min.tablet]: {
        padding: "0 1rem",

    },

    [media.min.laptop]: {
        marginBottom: "0",
    }
});

const Title = styled("div", {
    fontSize: "1rem",
    padding: "0.5rem 0",
    marginBottom: "1rem",
    borderTop: "1px solid " + colors.black,
    borderBottom: "1px solid " + colors.black,
});

const Form = styled("form", {
    position: "relative",
    
});

const Input = styled("input", {
    width: "100%",
    padding: "0.75rem 0",
    fontSize: "1rem",
    borderBottom: "1px solid " + colors.grey.light
});

const Button = styled("button", {
    position: "absolute",
    top: "5px",
    right: "0",
    cursor: "pointer",
    
    [media.min.laptop]: {
        top: "0.5rem",
    }
});

const RadioButtonWrapper = styled('div', {
    display: "flex",
    alignItems: "center",
    color: "#000",
    userSelect: "none",
    lineHeight: "12px",
    fontWeight: "normal",
    paddingTop: "1px",
    margin: "1.25rem 0 0rem 0",
})

const Errors = styled('span', {
    justifySelf: "flex-end",
    marginLeft: "auto",

});

class Newsletter extends React.Component {
    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.checkboxInput = React.createRef();
    }

    state = {
        acceptTerms: false,
        email: '',
        loading: false,
        errors: false,
        emailVerified: false,
        privacyIsChecked: false,
        submitting: false,
        signupComplete: false,
        fail: null,
        valid: false,
        result: '',
    }


    handleSubmit = async () => {
        const { privacyIsChecked, emailVerified, email } = this.state;
        
        this.setState({
            errors: true,
            loading: true,
        });

        if (!privacyIsChecked || !emailVerified) {
            this.setState({ loading: false });
        }
    
        if (!privacyIsChecked) {
            this.setState({
                errors: true,
                result: "Vanligen acceptera våra villkor"
            });
        }

        // Validate form
        if (privacyIsChecked && emailVerified) {

            let data = JSON.stringify({
                payload: {
                    email: email
                }
            });

            var xhr = new XMLHttpRequest()

            // get a callback when the server responds
            xhr.addEventListener('load', () => {
                const responResult = JSON.parse(xhr.response);

                if (responResult.result && responResult.result.length > 0 && responResult.result[0].data.length > 0) {
                
                    // Success
                    this.setState({signupComplete: true, result: "Tack för att du prenumererar!"})
                    
                    if (this.emailInput.current) {
                        this.emailInput.current.blur();
                        this.emailInput.current.value = '';
                    }

        
                    if (this.checkboxInput.current) {
                        this.checkboxInput.current.checked = false;
                    }

                    this.setState({
                        errors: false,
                        emailVerified: false,
                        privacyIsChecked: false,
                    });
                } else if (responResult.result.length > 0 && responResult.result[0].data.length === 0) {
                    // Duplicate
                    this.setState({
                        errors: true,
                        result: "E-posten är redan registrerad"
                    });
                }

                this.setState({ loading: false });
            })

            xhr.open('POST', 'https://bsjumk5qpb.execute-api.eu-north-1.amazonaws.com/customer/newsletter');
            xhr.send(data);
        }
    }

    handleCheckboxChange = e => {
        this.setState({ privacyIsChecked: e.target.checked });

        if (e.target.checked) {
            this.setState({
                errors: false,
                result: ""
            });
        }
    };

    handleEmailChange = ({ target }) => {
        let emailVerified = false;

        if (target.value.length < 1) {
            this.setState({ errors: false });
        }

        emailVerified = regExp.correctEmail.test(target.value);

        this.setState({ emailVerified, email: target.value, result: "" });
    };

    render() {
        const {
            privacyIsChecked,
            email,
            signupComplete,
            submitting,
            emailVerified,
            signupFailed,
            loading,
            errors,
            result
        } = this.state;


        return (
            <Wrapper>
                <Title>Prenumerera på vårt nyhetsbrev</Title>
                <Form onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.handleSubmit();
                    }}>
                                    
                    {signupComplete &&
                        <div>{result}</div>
                    }
                    {!signupComplete && 
                        <Input 
                            ref={this.emailInput} 
                            type="email" 
                            required 
                            placeholder="E-post"
                            name="email"
                            value={email}
                            onChange={this.handleEmailChange} 
                        />
                    }
                    {!signupComplete && 
                        <Button type="submit">
                            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.89966 20.7988L19.7992 10.8993L9.89966 0.999837" stroke="#C6C6C6" stroke-width="1.5"/>
                                <line x1="19.3308" y1="11.1875" x2="0.612407" y2="11.0307" stroke="#C6C6C6" stroke-width="1.5"/>
                            </svg>
                        </Button>
                    }
                    {!signupComplete && 
                        <RadioButtonWrapper>
                            <RadioButton
                                name="acceptTerms"
                                checked={privacyIsChecked}
                                onChange={e => this.handleCheckboxChange(e)}
                                ref={this.checkboxInput}
                            >
                                Jag accepterar&nbsp;<a href="/villkor" target="_blank" class="underline">villkoren</a>
                            </RadioButton>
                            {errors && <Errors>{result}</Errors>}
                        </RadioButtonWrapper>
                    }
                </Form>
            </Wrapper>
        );
    }
    
}

export default Newsletter;