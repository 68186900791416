import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";

const Text = styled("p", props => ({
  margin: "1rem 1rem 6rem 1rem",
  fontSize: props.$test === "big" ? "1.75rem" : "1.125rem",
  lineHeight: "1.875rem",

  [media.min.tablet]: {
    margin: props.$align === "left" ? "2rem 20% 4rem 2rem" : "2rem 4rem 2rem 20%",
    fontSize: props.$test === "big" ? "1.75rem" : "1.125rem",
    lineHeight: props.$test === "big" ? "40px" : "1.875rem",
  },

  [media.min.laptop]: {
    margin: props.$align === "left" ? "2rem 33% 7rem 2rem" : "2.5rem 0px 4rem 33%",
    maxWidth: props.$align === "left" ? "66.666%" : "600px",
  },

  [media.min.desktop]: { 
    margin: props.$align === "left" ? "2rem 35% 7rem 2rem" : "2.5rem 0px 4rem 35%",

  }
}));


const Ingress = ({ blok }) => (
  <SbEditable content={blok}>
      <Text $test={blok.style} $align={blok.align}>
        {blok.text}
      </Text>
  </SbEditable>
);

export default Ingress;