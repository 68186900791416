import React, { Fragment } from "react";
import { styled } from 'libs/styletron';
import Button from "../buttons/Button.js";
import { colors, media } from "../../config/theme";
import RadioButton from '../RadioButton';
import regExp from 'helpers/regExp';


const Fields = styled("div", {
  display: "flex",
  flexDirection: "column",
  margin: "0 -1rem",
  [media.min.tablet]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "0 -1rem",
  }
});

const Interested = styled("div", {
  
  borderBottom: "1px solid " + colors.black,
  marginBottom: "2.5rem",
  color: "#878787"
});

const Items = styled("div", {
  borderTop: "1px solid " + colors.black,
  color: colors.black,
  marginTop: "0.8rem"
});

const Item = styled("span", {
  border: "1px solid " + colors.black,
  boxSizing: "border-box",
  borderRadius: "6px",
  padding: "0.5rem 1.25rem",
  margin: "0.8rem 0.8rem 0.8rem 0",
  display: "inline-block"
});

const FieldTitle = styled("h2", {
  fontSize: "1.125rem",
  lineHeight: "1.375rem",
  margin: "0 0 1.25rem 1.5rem",
});

const FieldTitleWrapper = styled("div", {
  borderBottom: "1px solid #000",
  margin: '0 -0.5rem 1.875rem -0.5rem',

  [media.min.tablet]: {
    display: "none"
  }
});

const Field = styled("input", {
    width: 'calc(100% - 2rem)',
    margin: '0 1rem 1rem 1rem',
    padding: '0.5rem 0',
    fontSize: '1rem',
    fontFamily: 'NHaasGrotesk, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    borderBottom: '1px solid ' + colors.grey.dark,
    "-webkit-border-radius": "0",
    "-webkit-appearance": "none",

    [media.min.tablet]: {
      width: 'calc(50% - 2rem)',
    }
});

const TextField = styled("textarea", {
  width: 'calc(100% - 2rem)',
  margin: '2.75rem 1rem 1rem 1rem',
  padding: '0.5rem 0',
  fontSize: '1rem',
  fontFamily: 'NHaasGrotesk, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: '1px solid ' + colors.grey.dark,
  maxWidth: "calc(100% - 2rem)",
  height: "8.75rem",

  [media.min.tablet]: {
    minWidth: "47%",
    minHeight: "12%",
    maxWidth: "none",
    height: '19px',
    margin: '8.75rem 1rem 1rem 1rem',
  }
});

const Title = styled("div", {
  fontSize: "4rem",
  marginBottom: "5rem",

  [media.min.tablet]: {
    fontSize: "6.25rem",
  }
});

const Actions = styled("div", {

  marginTop: "1.5rem",


  [media.min.tablet]: {
    position: "sticky",
    width: "100%",
    bottom: "64px",
    marginTop: "40px",

    ["@media screen and (max-height: 720px)"]:{
      position: "static",
      marginTop: "40px",
      bottom: "0",
      width: "100%"    
    }
  }
})

const FormButton = styled(Button, props => ({
  width: "100%",
  borderRadius:"6px!important",

  [media.min.tablet]: {
    width: "calc(50% - 1rem)",
    borderRadius:"47px!important",
  }
}));

const Thanks = styled("div", {

});




const RadioButtonWrapper = styled('div', {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.black,
    userSelect: "none",
    lineHeight: "12px",
    fontWeight: "normal",
    paddingTop: "1px",
    margin: "1.25rem 0 3.5rem 0",
    [media.min.tablet]: {
      justifyContent: "flex-start",
      margin: "1.25rem 0 0 0",
    }
});

class Form extends React.Component {

  constructor(props) {
    super(props);

    const {speaker, course, interested, occasion} = this.props;

    this.state = {
      courseType: '',
      acceptTerms: false,
      complete: false,
      acceptTerms: false,
      email: '',
      loading: false,
      errors: false,
      emailVerified: false,
      privacyIsChecked: false,
      submitting: false,
      fail: null,
      valid: false,
      result: '',
      email: '',
      phone: '',
      company: '',
      discount: '',
      name: '',
      message: '',
      course: course,
      speaker: speaker,
      interested: interested,
      occasion: occasion
    }

    this.changeOccasion = this.changeOccasion.bind(this);
  }

  changeOccasion(occacion) {
    this.setState({
      occasion: occacion,
    });
  }

  handleChange = ({target}) => {
    
    if (target.name === 'acceptTerms') {
        this.setState({
            acceptTerms: !this.state.acceptTerms
        });
    } else {
        this.setState({
            [target.name]: target.value,
        });
    }
  }

  handleEmailChange = ({ target }) => {
    let emailVerified = false;

    if (target.value.length < 1) {
        this.setState({ errors: false });
    }

    emailVerified = regExp.correctEmail.test(target.value);

    this.setState({ emailVerified, email: target.value, result: "" });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const { privacyIsChecked, emailVerified, name, email, phone, company, discount, message, speaker, course, occasion, interested } = this.state;
    let text = "";

    const subject = speaker || course ? "GrebAcademy - Boka" : "GrebAcademy - Kontakt";

    if (speaker) {
      text = "Jag vill boka föreläsare " + speaker + ".";
    } else if (course) {
      text = "Jag är intresserad av eventet " + course + ".";

      if (occasion) {
        text += " Plats: " + occasion + ".";
      }
    }


    let data = JSON.stringify({
      payload: {
        emailDetails: {
            from: "info@grebacademy.com",
            to: [
              "info@grebacademy.com"
            ],
            subject: subject,
            html: '',
            emailType: 'template_showroom',
            attachments: []
        },
        params: {
            message: message,
            name: name,
            company: company,
            email: email,
            phone: phone,
            text: text,
            discount: discount
        }
      }
    });

    var xhr = new XMLHttpRequest()

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      const responResult = JSON.parse(xhr.response);

      this.setState({ complete: true, loading: false });
    })

    xhr.open('POST', 'https://bsjumk5qpb.execute-api.eu-north-1.amazonaws.com/send');
    xhr.send(data);

    this.setState({ complete: true, loading: false });

  }


  render() {
    const {speaker, course, interested, occasion} = this.props;
    const {
      acceptTerms,
      privacyIsChecked,
      email,
      name,
      phone,
      company,
      discount,
      message,
      signupComplete,
      submitting,
      emailVerified,
      signupFailed,
      loading,
      errors,
      result
    } = this.state;

  const buttonText = speaker ? "Skicka förfrågan" : "Kontakta oss";

  return (
    <Fragment>
      {!this.state.complete &&
      <Fragment>
        <Title>
           {this.props.title}
        </Title>

        <form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            this.handleSubmit();
            }}>
          {speaker && 
            <Interested>
              Jag är intresserad av
              <Items>
                <Item>{speaker}</Item>
              </Items>
            </Interested>
          }
          {course && 
            <Interested>
              Jag är intresserad av
              <Items>
                <Item>{course}</Item>
                {occasion && 
                 <Item>{occasion}</Item>
                }
              </Items>
            </Interested>
          }
          {interested && 
            <Interested>
              Jag är intresserad av
              <Items>
                <Item>{interested}</Item>
              </Items>
            </Interested>
          }
          <Fields>
            <FieldTitleWrapper>
              <FieldTitle>Kontaktuppgifter</FieldTitle>
            </FieldTitleWrapper>
            <Field id="name" name="name" placeholder="Fullständigt namn*" type="text" required value={name} onChange={this.handleChange} />
            <Field id="email" name="email" placeholder="E-post*" type="email" required  value={email}  onChange={this.handleEmailChange} />
            <Field id="phone"  name="phone"  placeholder="Telefon" type="tel"  value={phone} onChange={this.handleChange} />
            <Field id="company"  name="company"  placeholder="Företag" type="text" required  value={company} onChange={this.handleChange} />
            {course && 
              <Field id="discount"  name="discount"  placeholder="Rabattkod" type="text" value={discount} onChange={this.handleChange} />
            }
            <TextField id="message" name="message" placeholder="Meddelande" type="textarea" value={message} onChange={this.handleChange} ></TextField>
          </Fields>
        
          <Actions>
            <FormButton color={colors.white} theme="dark" type="submit">
              {buttonText}
            </FormButton>
            {/* <Button color={colors.white} theme="dark" width="calc(50% - 1rem)" type="submit">
              {buttonText}
            </Button> */}
            <RadioButtonWrapper>
                <RadioButton
                  name="acceptTerms"
                  checked={acceptTerms}
                  onChange={this.handleChange}
                  
                >
                  Jag accepterar&nbsp;<a href="/villkor"target="_blank" style={{textDecoration: "underline"}}>villkoren</a>
                </RadioButton>
            </RadioButtonWrapper>
          </Actions>
        </form>
        </Fragment>
      }
      {this.state.complete && 
        <Fragment>
          <Title>Tack!</Title>
          <Thanks>
            <p>Vi har mottagit ditt meddelande och kontaktar dig så snart vi kan.</p>
            <br />
            <p>/GrebAcademy</p>
          </Thanks>
        </Fragment>
      }
      </Fragment>
      )
    }
  }

  export default Form;