import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "../../config/theme";
import BlokImage from "components/BlokImage";

function truncate( str, n, useWordBoundary ) {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary 
      ? subString.substr(0, subString.lastIndexOf(" ")) 
      : subString) + "...";
};

const Item = styled("div", props => ({
    flex: "0 0 100%",

    position: "relative",
    // minHeight: "30rem",
    // marginBottom: "4.5rem",
    marginBottom: props.$length === 1 || props.$index === 1 ? "0" : "4.5rem",

    [media.min.tablet]: {
        marginBottom: "0",
        flex: "0 0 50%",
        padding: "0px 0.75rem 0rem",
    },
  
    [media.min.laptop]: {
        flex: "0 0 50%",
        padding: "0px 1rem 0rem",
    },
}));

const Image = styled("div", props => ({
    height: props.$index === 1 ? "293px" : "400px",
    width: "100%",
    position: "relative",
    marginBottom: "1.125rem"
}));

const Org = styled("span", {
    display: "inline-block",
    borderBottom: "1px solid " + colors.black
});

const Info = styled("div", {
   marginBottom: "0.5rem"
});

const Text = styled("p", {
    marginBottom: "1rem",
    color: "#7e7e7e"
 });

const Name = styled("h3", {
   fontSize: "1rem",

   [media.min.tablet]: {
    },

    [media.min.laptop]: {
        fontSize: "1rem",
        marginBottom: "0.2rem"
    },
});

const Title =  styled("div", props => ({
    fontSize: "1rem",
    [media.min.laptop]: {
        fontSize: "1rem",
    },
 }));


const Speaker = ({ blok, index, length }) => {
    return (
        <Item $index={index} $length={length}>
            <a href={blok.url}>
                <Image $index={index}>
                    <BlokImage
                        wrapperStyle={{
                            height: "100%",
                            width: "100%",
                            position: "absolute", 
                        }}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={blok.title}
                        fluid={{}}
                        blok={blok.image && blok.image.filename}
                    />
                </Image>
                <Info>
                    <Name>{blok.name}—</Name>
                    <Title>{blok.title} at <Org>{blok.organization}</Org></Title>
                </Info>
                <Text>{truncate(blok.text, 200, true)}</Text>
            </a>
        </Item>
    )
}   

export default Speaker;