import React, { useEffect, useState, useRef } from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import Column from "components/layouts/Column";
import DefaultWrapper from "components/layouts/DefaultWrapper";
import { FilterArrow } from 'components/ui/icon/icon'
import FilterList from "./FilterList";
import RadioButton from "../../components/RadioButton";
import { Above,Below } from 'components/Breakpoints';

const Wrapper = styled("div", {
    display: "flex",
    flexDirection: "column",
});

const Top = styled("div", {
    display: "flex",
    justifyContent: "space-between"
});

const TitleWrapper = styled("div", {
    display: "flex",
});

const Title = styled("h2", {
    fontSize: "1rem",
    lineHeight: "1.25rem"
});

const ArrowWrapper = styled("button", props => ({
    height: "fit-content",
    margin: "6px 0 0 15px",
    transform: props.$open ? "rotate(0deg)" : "rotate(180deg)",
    // transition: "transform 0.3s ease-in-out"
}));

const Arrow = styled(FilterArrow, {

});

const Filter = styled("div", {

});

const Bottom = styled("div", {

});

const Text = styled("span", props =>  ({
    fontSize: "1rem",
    lineHeight: "1.25rem"
}));

const MobileFilter = ({ blok }) => {
    const [toggleFilter, setToggleFilter] = useState(false);
    return (
        <Wrapper>
            <Top>
                <TitleWrapper>
                    <Title>Filter</Title>
                    <ArrowWrapper 
                        $open={toggleFilter} 
                        onClick={() => setToggleFilter(!toggleFilter)} 
                        className="filter-arrow">
                        <Arrow width={22} height={22} color="#000000" />
                    </ArrowWrapper>
                </TitleWrapper> 
            </Top>
        </Wrapper>
    );
};

export default MobileFilter;