import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";


const Style = styled("div", props => ({

  [media.min.tablet]: {
    width: props.$layout === "vertical" ? "auto" : props.$space === '4' ? 'calc(33.3333% - 2rem)' : 'calc(66.6666% - 2rem)  ',
    padding: "0 1rem",
  },

}));

const Column = ({ space, layout, children }) => (
    <Style $space={space} $layout={layout}>
        {children}
    </Style>
);

export default Column;