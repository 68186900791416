import React from "react";
import BlokImage from "components/BlokImage";
import RadioButton from 'components/RadioButton';
import { styled } from 'libs/styletron';
import regExp from 'helpers/regExp';
import { colors, media } from "config/theme";


const Wrapper = styled("div", {
    marginBottom: "5rem",
    borderBottom: "0.6px solid #7e7e7e",
    [media.min.tablet]: {
        display: "flex",
        flexDirection: "row-reverse",
        borderBottom: "none",
    },
    [media.min.laptop]: {
        marginBottom: "7.5rem",
    },
    [media.min.maxWidth]: {
        marginBottom: "10rem",
    },
    
});

const Text = styled("div", {
    fontSize: "1.25rem",
    lineHeight: "1.3",
    position: "relative",
    padding: "0 1rem",
    borderTop: "none",
    borderBottom: "none",

    [media.min.laptop]: {
        width: "41.666666666%",
        borderTop: "0.8px solid "+ colors.grey.light,
        borderBottom: "0.8px solid " + colors.grey.light,
        padding: "0 2rem"
    },

    [media.min.maxWidth]: {
        padding: "0 4rem"
    }
});

const P = styled("p", {
    maxWidth: "500px",
    fontSize: "1.125rem",
    lineHeight: "1.375rem"
});

const Title = styled("div", {
    fontSize: "3rem",
    lineHeight: "3.25rem",
    marginBottom: "1rem",
    marginTop: "2.2rem",
    [media.min.maxWidth]: {
        marginTop: "3.375rem"
    }
});

const Image = styled("div", {
    fontSize: "5rem",
    position: "relative",
    padding: "0",
    marginBottom: "1.25rem",

    [media.min.laptop]: {
        display: "flex",
        flexDirection: "row-reverse",
        padding: "0",
        marginBottom: "0",
        maxHeight: "700px",
        width: "55.333333%"
    }
});

const Upper = styled("div", {
    marginBottom: "2.5rem",

    [media.min.laptop]: {
        marginBottom: "0",
    }
    
});

const Lower = styled("div", {
    [media.min.laptop]: {
        position: "absolute",
        bottom: "0",
        width: "calc(75% - 2rem)",
    }
});

const Form = styled("form", {
    position: "relative",
});

const Input = styled("input", {
    width: "100%",
    padding: "0.75rem 0",
    fontSize: "1.25rem",
    borderBottom: "0.5px solid " + colors.grey.light,
    "::placeholder": {
        color: colors.grey.light
    },
    ":-ms-input-placeholder": {
        color: colors.grey.light
    },
    "::-ms-input-placeholder": {
        color: colors.grey.light
    },
    [media.min.laptop]: {
        fontSize: "1.875rem"
    }
});

const Button = styled("button", {
    position: "absolute",
    top: "5px",
    right: "0",
    cursor: "pointer",
    
    [media.min.laptop]: {
        top: "1rem",
    }
});

const RadioButtonWrapper = styled('div', {
    display: "flex",
    alignItems: "center",
    color: colors.black,
    userSelect: "none",
    lineHeight: "1.375rem",
    fontWeight: "normal",
    fontSize: "1.125rem",
    paddingTop: "1px",
    margin: "1rem 0 4rem 0",
    [media.min.maxWidth]: {
        margin: "1.25rem 0 4rem 0"
    }
})


const Thanks = styled('div', {
    margin: "0 0 2rem 0"
})

class Newsletter extends React.Component {
    constructor(props) {
        super(props);
        this.emailInput = React.createRef();
        this.checkboxInput = React.createRef();
    }

    state = {
        acceptTerms: false,
        email: '',
        loading: false,
        errors: false,
        emailVerified: false,
        privacyIsChecked: false,
        submitting: false,
        signupComplete: false,
        fail: null,
        valid: false,
        result: '',
    }


    handleSubmit = async () => {
        const { privacyIsChecked, emailVerified, email } = this.state;
   
        this.setState({
            errors: true,
            loading: true,
        });

        if (!privacyIsChecked || !emailVerified) {
            this.setState({ loading: false });
        }
    
        if (!privacyIsChecked) {
            this.setState({
                errors: true,
                result: "Vanligen acceptera våra villkor"
            });
        }


        // Validate form
        if (privacyIsChecked && emailVerified) {

           

            let data = JSON.stringify({
                payload: {
                    email: email
                }
            });

            var xhr = new XMLHttpRequest()

            // get a callback when the server responds
            xhr.addEventListener('load', () => {
                const responResult = JSON.parse(xhr.response);

                if (responResult.result.length > 0 && responResult.result[0].data.length > 0) {
                
                    // Success
                    this.setState({signupComplete: true, result: "Tack för att du prenumererar!"})
                    
                    if (this.emailInput.current) {
                        this.emailInput.current.blur();
                        this.emailInput.current.value = '';
                    }

        
                    if (this.checkboxInput.current) {
                        this.checkboxInput.current.checked = false;
                    }

                    this.setState({
                        errors: false,
                        emailVerified: false,
                        privacyIsChecked: false,
                    });
                } else if (responResult.result.length > 0 && responResult.result[0].data.length == 0) {
                    // Duplicate
                    this.setState({
                        errors: true,
                        result: "E-posten är redan registrerad"
                    });
                }

                this.setState({ loading: false });
            })

            xhr.open('POST', 'https://bsjumk5qpb.execute-api.eu-north-1.amazonaws.com/customer/newsletter');
            xhr.send(data);
        }
    }

    handleCheckboxChange = e => {
        this.setState({ privacyIsChecked: e.target.checked });

        if (e.target.checked) {
            this.setState({
                errors: false,
                result: ""
            });
        }
    };

    handleEmailChange = ({ target }) => {
        let emailVerified = false;

        if (target.value.length < 1) {
            this.setState({ errors: false });
        }

        emailVerified = regExp.correctEmail.test(target.value);

        this.setState({ emailVerified, email: target.value, result: "" });
    };

    render() {
        const {
            acceptTerms,
            privacyIsChecked,
            email,
            signupComplete,
            submitting,
            emailVerified,
            signupFailed,
            loading,
            errors,
            result
        } = this.state;

        const {blok} = this.props;
    
        return (
            <Wrapper>
                <Image>
                    <BlokImage
                        wrapperStyle={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        filter: "brightness(0.77)",
                        top: "0",
                        left: "0",
                        }}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={blok.title}
                        fluid={{}}
                        blok={blok.image.filename}
                    />
                
                </Image>
                <Text>
                    <Upper>
                        <Title>{blok.title}</Title>
                        <P>{blok.text}</P>
                    </Upper>
                    <Lower>
                       
                        <Form onSubmit={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.handleSubmit();
                            }}>
                            
                            {signupComplete &&
                                <Thanks>{result}</Thanks>
                            }
                            
                            {!signupComplete && 
                            <Input 
                                ref={this.emailInput} 
                                type="email" 
                                required 
                                placeholder="E-post"
                                name="email"
                                value={email}
                                onChange={this.handleEmailChange} 
                            />
                            }
                            {!signupComplete && 
                                <Button type="submit">
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.89966 20.7988L19.7992 10.8993L9.89966 0.999837" stroke={colors.grey.light} stroke-width="1.5"/>
                                        <line x1="19.3308" y1="11.1875" x2="0.612407" y2="11.0307" stroke={colors.grey.light} stroke-width="1.5"/>
                                    </svg>
                                </Button>
                            }
                            {!signupComplete && 
                                <RadioButtonWrapper>
                                    <RadioButton
                                        name="acceptTerms"
                                        checked={privacyIsChecked}
                                        onChange={e => this.handleCheckboxChange(e)}
                                        ref={this.checkboxInput}
                                    >
                                        Jag accepterar&nbsp;<a className="a-underline" href="/villkor" target="_blank">villkoren</a>
                                    </RadioButton>
                                </RadioButtonWrapper>
                            }
                        </Form>
                    </Lower>
                </Text>
            </Wrapper>
        );
    }
    
}

export default Newsletter;