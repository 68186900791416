import React from 'react';
import { styled } from 'libs/styletron';

const StyledButton = styled('a', props => ({
    borderRadius: "47px",
    color: `${props.$theme === "clear" ? props.color : props.$theme === "dark" ? "#fff" : "#000"}`,
    padding: "0.8rem 44px 0.8rem",
    border: `1px solid #000`,
    background: `${props.$theme === "clear" ? "none" : props.$theme === "dark" ? "#000" : "#fff"}`,
    cursor: "pointer",
    position: "relative",
    width: `${props.$width || "auto"}`,

    transition: "all .2s ease",

    fontSize: "1.125rem",

    ':hover': {
       /* background: `${props.$theme === "dark" ? "#fff" : "#000"}`,
        color: `${props.$theme === "dark" ? "#000" : "#fff"}`*/
        borderColor: "rgba(0,0,0,0.0)",
        background: "rgba(0,0,0,1.0)",
        color: "#fff"
    }
}));


const Button = ({fill, color, theme, width, children, ...buttonProps }) => {
 

    return (
        <StyledButton $fill={fill} $color={color} $theme={theme} $width={width} {...buttonProps}>
            {children}
        </StyledButton>
    );
};


export default Button;