import React from "react";
import SbEditable from "storyblok-react";
import { Link } from "gatsby"
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import BlokImage from "components/BlokImage";
import { colors } from "../../config/theme";

const HeroWrapper = styled("div", props => ({
  position: "relative",
  height: "40vh",

  [media.min.tablet]: {
    height: "60vh",
  },

  [media.min.laptop]: {
    height: "calc(100vh - 64px)",
    marginTop: "4rem",
  }
}));

const ReadMore = styled(Link, {
  display: "none",

  [media.min.laptop]: {
    position: "absolute",
    color: "#fff",
    border: "1px solid #fff",
    bottom: "40px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flex: "1 1 0",
    alignItems: "center",
    padding: "0.7rem 2rem",
    borderRadius: "50px",

    ":hover": {
      background: "#fff",
      color: "#000",
    }

    
  }
});

const Span = styled('span', {
  color: "#fff",
});


const Nav = styled('div', {
  color: "#fff",
  position: "absolute",
  top: "1rem",
  left: "1rem",
  zIndex: "2",

  [media.min.laptop]: {
    top: "2rem",
    left: "2rem",
  }
});

const BreadCrumb = styled('a', props => ({
  color: props.$as === "span" ? colors.grey.light : "#fff !important",
}));

const Devider = styled('span', {
  margin: "0 0.25rem"
});

const Arrow = styled('svg', {
  marginLeft: "10px",
});


const ProductHero = ({ blok, name, type }) => {
  type = type === "course" ? "Kurser" : type === "lecture" ? "Föreläsningar" : type;

  return (
    <SbEditable content={blok}>
      <HeroWrapper>
        <Nav>
          <BreadCrumb href="/utbildning/">Alla utbildningar</BreadCrumb>
          <Devider>/</Devider>
          <BreadCrumb>{type}</BreadCrumb>
          <Devider>/</Devider>
          <BreadCrumb $as="span">{name}</BreadCrumb>
        </Nav>
        <BlokImage
          wrapperStyle={{
            height: "100%",
            width: "100%",
            position: "absolute",
            filter: "brightness(0.77)",
            top: "0",
            left: "0",
          }}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={blok.title}
          fluid={{}}
          blok={blok.image}
        />

        <ReadMore className="read-more-btn" to="#about">
          <Span>Läs mer</Span>
          <Arrow width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.844727 6.36401L7.20869 12.728L13.5725 6.36421" stroke="white"/>
            <line y1="-0.5" x2="12.0335" y2="-0.5" transform="matrix(0.00836097 -0.999965 0.999965 0.00839148 7.50586 12.4308)" stroke="white"/>
          </Arrow>
        </ReadMore>
      </HeroWrapper>
    </SbEditable>
  );
};

export default ProductHero;
