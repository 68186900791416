import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "../../config/theme";
import SbEditable from 'storyblok-react';



const Item = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 2rem",
    [media.min.tablet]: {
        padding: "0 5.66rem"
    }
});


const Title = styled("h2", props => ({
    fontSize: "1.875rem",
    lineHeight: "2.125rem",

    [media.min.tablet]: {
        fontSize: "3rem",
        lineHeight: "3.25rem",
        height: "120px",
        display: "flex",
        alignItems: "flex-end",
    },

    color: colors.grey.light,
    transition: "all .5s ease-in-out",
}));

const Description = styled("p", props => ({
    fontSize: "1rem",
    lineHeight: "1.25rem",

    [media.min.tablet]: {
        fontSize: "1.125rem",
        lineHeight: "1.5rem"
    },

    marginBottom: "2.5rem",
    color: colors.grey.light,
    transition: "all .5s ease-in-out"
}));

const TextWrapper = styled("div", props => ({
    width: "100%",
    [media.min.tablet]: {
        width: "444px",
    }
}));

const Image = styled("img", props => ({
    width: "268.5px",
    height: "168.5px",
    objectFit: "cover",
    [media.min.tablet]: {
        width: "537px",
        height: "337px"
    },
    transform: 'scale(1) rotate(0.01deg)',
    transition: "transform  .5s ease-in-out"
}));


const Slide = ({ blok }) => {
    const link = blok.link.url.trim() !== "" ? blok.link.url : blok.link.cached_url;

    return (
        <SbEditable content={blok}>
            <Item $as={link === "" ? "div" : "a"}  href={link}>
                <TextWrapper>
                    <Title>{blok.title}</Title>
                    <Description>{blok.description}</Description>
                </TextWrapper>
                <Image src={blok.media.filename} /> 
            </Item>
        </SbEditable>
    )
}   

export default Slide;