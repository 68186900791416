import React from 'react';
import { styled } from 'libs/styletron';
import PropTypes from 'prop-types';


const Label = styled('label', {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  position: "relative",
  width: "fit-content",
  marginRight: "0.5rem"
  /*input:checked ~ span {
    div {
      background-color: ${({ color }) => color};
    }
  }*/
})

const Text = styled('p', {
  userSelect: 'none',
  margin: "0 0 0  0.5rem",
  color: "#000",
  paddingTop: "1px"
})

const Button = styled('span', { 
  border: "1px solid #000",
  borderRadius: "50%",
  height: "16px",
  width: "16px",
  minWidth: "14px",
  background: "transparent",
  cursor: "pointer",
  margin: "0 0.5rem 0 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const InvisibleInput = styled('input', {
  position: "absolute",
  top: "0",
  left: "0",
  opacity: "0",
  cursor: "pointer",
  height: "0",
  width: "0",
})

const InnerCircle = styled('div', props => ({
  background: props.$checked ? "#000" : "#fff",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  transition: "background 0.2 ease"
}))

const RadioButton = ({
  text,
  color,
  circleSize,
  name,
  onChange,
  textTransform,
  letterSpacing,
  textMargin,
  margin,
  checked,
  fontSize,
  id,
  children
}) => (
    <Label color={color} margin={margin} onChange={e => onChange(e)}>
      <InvisibleInput id={id} type="checkbox" value={text} name={name} defaultChecked={checked} />
      <Button color={color} circleSize={circleSize}>
        <InnerCircle circleSize={`calc(${circleSize} - 6px)`} $checked={checked} />
      </Button>
      <Text textMargin={textMargin} color={color} textTransform={textTransform} letterSpacing={letterSpacing} fontSize={fontSize}>
        {children}
      </Text>
    </Label>
  );

RadioButton.propTypes = {
  checked: PropTypes.bool,
  circleSize: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  id: PropTypes.string,
  letterSpacing: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  textMargin: PropTypes.string,
  textTransform: PropTypes.string,
};

RadioButton.defaultProps = {
  checked: false,
  circleSize: '14px',
  color: "#000",
  id: '',
  letterSpacing: '',
  margin: '',
  name: '',
  text: '',
  textMargin: '',
  textTransform: '',
};

export default RadioButton;
