import React, { useRef } from "react";
import CourseItem from './GridItem';
import { styled } from 'libs/styletron';
import { media } from "../../config/theme";
import SlideBar from "../../components/SlideBar";


const CourseList = styled("div", props => ({ 
    display: "flex",
    flexFlow: props.$mobileSlider ? "row" : "wrap",
    overflow: "scroll",

    "::-webkit-scrollbar": {
        width: "0",
        background: "transparent"
    },

    "::-webkit-scrollbar-thumb": {
        background: "#ffffff",
        height: "0"
    },

    [media.min.tablet]: {
        flexWrap: "wrap"
    },
    
}));


const Courses = ({ blok, short, mobileSlider }) => {
    const slideRef = useRef();

    return (
        <>
            <CourseList ref={slideRef} $mobileSlider={mobileSlider} $short={short}>
                {blok.course?.map(blok =>
                    React.createElement(CourseItem, {
                        key: blok._uid,
                        blok: blok,
                        short: short,
                        mobileSlider: mobileSlider
                    })
                )}
            </CourseList>
            {mobileSlider ? 
                <SlideBar mobileSlider={mobileSlider} slideRef={slideRef} />
            : null}
        </>
    )

}

export default Courses;