import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';

import {
  getFluidGatsbyImage,
  getFixedGatsbyImage,
} from 'gatsby-storyblok-image';

const validImageUrlPattern = /^(https?:)?\/\/a.storyblok.com\/f\/[0-9]+\/[0-9]+x[0-9]+\/[A-Za-z0-9]+\/[\S]+\.[a-z]+/;

const getImageUrl = (image) => {
  let url = null;

  if (typeof image === 'string') {
    url = image;
  }

  if (typeof image === 'object') {
    if (image.fieldtype === 'asset') {
      url = image.filename;
    } else {
      url = image.image;
    }
  }

  url = validImageUrlPattern.test(url) ? url : null;

  if (!url) {
    return '';
  }

  return url;
};

const BlokImage = ({
  aspectRatio,
  blok,
  imgTransition,
  fixed,
  fluid,
  imgStyle,
  isBackground,
  sizeMultiplier,
  wrapperStyle,
  ...rest
}) => {
  const imageUrl = getImageUrl(blok);
  const imgDimensions = imageUrl
    ? imageUrl.split('/')[5].split('x')
    : [100, 100];
  const defaultFluidProps = {
    maxWidth: Math.round(
      (Math.min(imgDimensions[0], 2000) - 1) * sizeMultiplier
    ),
    maxHeight:
      Math.round(
        (Math.min(imgDimensions[0], 2000) - 1) * aspectRatio * sizeMultiplier
      ),
    quality: 65,
  };

  const defaultBlurProps = {
    width: 10,
    height: 10,
    quality: 10,
  };

  const defaultFixedProps = {
    width: 400,
    quality: 65,
  };

  const fluidProps =
    fluid && !fixed
      ? getFluidGatsbyImage(blok, Object.assign(defaultFluidProps, fluid))
      : null;

  const blurProps = getFixedGatsbyImage(blok, Object.assign(defaultBlurProps));

  const fixedProps =
    fixed && !fluid
      ? getFixedGatsbyImage(blok, Object.assign(defaultFixedProps, fixed))
      : null;

  const ImgType = isBackground ? BackgroundImage : Img;

  const blurImgUrl = blurProps ? blurProps.src : '';
  const blurUpStyling = isBackground
    ? null
    : {
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url('${blurImgUrl}')`,
      };

  return (
      <ImgType
        fluid={fluidProps}
        fixed={fixedProps}
        style={{ ...wrapperStyle, ...blurUpStyling }}
        imgStyle={{ transition: imgTransition, ...imgStyle }}
        {...rest}

      />
  );
};

BlokImage.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  blok: PropTypes.oneOfType([
    PropTypes.shape({
      filename: PropTypes.string,
    }),
    PropTypes.shape({
      image: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  fadeIn: PropTypes.bool,
  fixed: PropTypes.object,
  fluid: PropTypes.object,
  imgStyle: PropTypes.object,
  imgTransition: PropTypes.string,
  isBackground: PropTypes.bool,
  sizeMultiplier: PropTypes.number,
  title: PropTypes.string,
  wrapperStyle: PropTypes.object, // When using object-fit cover, use object-position: 50% 50% for IE compatibility
};

BlokImage.defaultProps = {
  alt: '',
  aspectRatio: null,
  fadeIn: true, // use transition
  fixed: null, // These are passed onto gatsby-plugin-sharp:
  fluid: null, // https://www.gatsbyjs.org/packages/gatsby-plugin-sharp
  imgStyle: {},
  imgTransition: 'transform .4s cubic-bezier(.47,0,.745,.715)', // bielkeyang special :D
  isBackground: false,
  sizeMultiplier: 1,
  title: '',
  wrapperStyle: {},
  //Full list of props found here: https://www.gatsbyjs.org/packages/gatsby-image/
};

export default BlokImage;