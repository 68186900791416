import React, { Fragment, PureComponent } from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import setTheme from 'helpers/setTheme';
import SpeakerHero from 'modules/Hero/SpeakerHero';
import TitleText from 'modules/TitleText';
import Components from "components/components";
import Form from "components/Form/DefaultForm";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Courses from 'modules/ProductGrid';
import DefaultWrapper from "components/layouts/DefaultWrapper";
import MetaData from 'components/Metadata';

const H2 = styled("div", props => ({
  margin: "2rem 0.5rem 2rem 0.5rem",

  fontSize: "1.5625rem",
  lineHeight: "1.8125rem",
  
  [media.min.tablet]: {
    margin: "0 1rem 4rem 1rem"
  },

  [media.min.desktop]: {
    fontSize: "1.875rem",
    lineHeight: "2.125rem",
  }
}));

class Speaker extends PureComponent {

  
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.formRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);

  }

  componentDidMount() {
    setTheme('light', true);
    window.scrollTo(0,0);

    //this.scrollFunc();
  }

  componentWillUnmount(){
  }
  
  handleClick() {
    this.formRef.current.toggleForm();
  }


  scrollFunc() {
    if (this.titleRef.current == null) return
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    ScrollTrigger.create({
      trigger: this.titleRef.current,
      pin: true,
      start: "top top",
      markers: true,
      pinSpacing: false
    });
  }

  render() {
    const {blok, name, full_slug, courses, } = this.props;
    let seoMeta, seoImage, overrideTitle;

    seoMeta = blok.text;
    const titletext = {
      text: blok.text,
      title: "Om"
    }

    let course = courses?.map((blok, index) => (
      {
          _uid: "key",
          course: {
              name: blok.name,
              full_slug: blok.url,
              tag_list: [
                  ""
              ],
              content: {
                  hero: [
                          {
                              Text: blok.text,
                              image: blok.image
                          }
                  ],
                  type: blok.type,
                  topic: blok.topic,
                  courseLocation: blok.courseLocation,
                  cost: blok.cost,
                  speakers: []
              }
          }, 
          component: "course_item"
      }
  ));

  let courseBlock = {
      course
  };

    return (
      
      <Fragment>
        <MetaData
          type="website"
          seoTitle={name}
          url={full_slug}
          overrideTitle={overrideTitle}
          seoMetaDescription={seoMeta}
          seoOgimage={seoImage}
        />
          <SpeakerHero blok={blok} name={name} onClick={this.handleClick}  />
          <TitleText blok={titletext} />
          
          {blok.body &&
            blok.body.map((blok) =>
              React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            )}
          
          {Object.keys(courseBlock).length !== 0 && courseBlock && courseBlock.course && courseBlock.course.length > 0 &&
            <div id="education">
              <DefaultWrapper mobileSlider={true}>
                <H2>Utbildningar</H2>
                <Courses mobileSlider={true} blok={courseBlock} />
              </DefaultWrapper>
            </div>
          }

          <Form
            title="Boka"
            speaker={name}
            ref={this.formRef}
          />
      </Fragment>
    );
  }
};

export default Speaker;