import React from "react";
import Slide from './TextSlide';
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderWrapper from "react-slick";
import Fade from 'react-reveal/Fade';
import { Above,Below } from 'components/Breakpoints';
import TextSlider from 'modules/TextSlider';

const Wrapper = styled("div", { 
    position: "relative",
    marginBottom: "4rem",
    height: "auto",
    [media.min.tablet]: {
        marginBottom: "8.25rem"
    }, 
    borderTop: "0.8px solid #c6c6c6"
})

const Title = styled("h2", {
    fontSize: "1.875rem",
    lineHeight: "2.125rem",
    margin: "1rem 0  1rem",

    [media.min.tablet]: {
        margin: "1rem 0 6.25rem 2rem",
    },

    [media.min.laptop]: {
        margin: "1rem 0 6.25rem 2rem",
    }
});

const NextArrow = (props) => {
    return (
        <button className="slick-next slick-arrow" onClick={props.onClick}  type="button">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.48657 17.9708L16.9719 9.48554L8.48657 1.00026" stroke="black" stroke-width="1.5"/>
                <line x1="16.5694" y1="9.83933" x2="0.525104" y2="9.70493" stroke="black" stroke-width="1.5"/>
            </svg>
        </button>
    )
};

const PrevArrow = (props) => {
    return (
        <button className="slick-prev slick-arrow"  onClick={props.onClick}  type="button">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4853 1L2.00001 9.48528L10.4853 17.9706" stroke="black" stroke-width="1.5"/>
                <line x1="2.40246" y1="9.1315" x2="18.4468" y2="9.26589" stroke="black" stroke-width="1.5"/>
            </svg>
        </button>
    )
};


const SlideTrackContent = styled("p", props =>  ({
    fontSize: "1rem",
    position: "Absolute",
    left: "1rem",
    bottom: "0",

    [media.min.tablet]: {
        bottom: "-5rem",
        left: "2rem"
    },
    [media.min.laptop]: {
        bottom: "-5rem"
    },
    [media.min.maxWidth]: {
        left: "4rem"
    }
}));

const TextSlideshow = ({ blok }) => {
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        draggable: true
    };

    let slideTrack = (blok.text_slide.length < 10) ? '01—0' : "01—";

    const textSlide = blok.text_slide;

    let slides = textSlide.map((b) => (
        {
            title: b.Title,
            text: b.Text
        }
    ));

    let textBlok = {
        slides: slides,
        component: blok.component,
        title: blok.title,
        _uid: "key",
    };

    return (
        <Wrapper className="text-slider">
            <Fade>
                <Title>{blok.title}</Title>
                <Below breakpoint="md">
                    <TextSlider blok={textBlok}></TextSlider>
                </Below>
                <Above breakpoint="md">
                    <SliderWrapper {...settings}>
                            {blok.text_slide.map(blok =>
                                React.createElement(Slide, {
                                    key: blok._uid,
                                    blok: blok,
                                })
                            )}
                    </SliderWrapper>
                    <SlideTrackContent>{slideTrack + blok.text_slide.length}</SlideTrackContent>
                </Above>
                </Fade>
        </Wrapper>
    )
} 

export default TextSlideshow;