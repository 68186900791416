import { StyleObject, driver, getInitialStyle } from 'styletron-standard';
import {
    createStyled,
    useStyletron as useDefaultStyletron,
    withStyleDeep,
    withTransform,
    withWrapper,
} from 'styletron-react';

// @todo: Make this non project specific.
import { breakpoints } from 'config/theme/media';
import fontSizes from 'config/theme/font-sizes';

const bracketsMap: { [key: string]: number } = { '(': 1, ')': -1 };

const parseValue = (value: string) => {
    const values = [];
    let temp = '';
    let bracketsCount = 0;
    const trim = value.toString().trim();

    for (let i = 0; i < trim.length; i += 1) {
        const char = trim.charAt(i);
        const inBrackets = bracketsCount > 0;

        if (!inBrackets && char === ' ') {
            values.push(temp);
            temp = '';
        } else {
            temp += char;
        }

        bracketsCount += bracketsMap[char] || 0;
    }

    if (temp) {
        values.push(temp);
    }

    return values;
};

const circularParser = (resolve: Function, value: string) => {
    const longhands: Record<string, string> = {};
    const values = parseValue(value);

    longhands[resolve('Top')] = values[0];
    longhands[resolve('Right')] = values[1] || values[0];
    longhands[resolve('Bottom')] = values[2] || values[0];
    longhands[resolve('Left')] = values[3] || values[1] || values[0];

    return longhands;
};

const transforms: any = {
    padding: (value: string) => circularParser((key: string) => `padding${key}`, value),
    margin: (value: string) => circularParser((key: string) => `margin${key}`, value),
    fontSize: (value: string) => ({
        fontSize: typeof value === 'string' ? value : fontSizes[value],
    }),
};

const transformStyleObject = (styles: StyleObject) => {
    let result: StyleObject = {};

    for (const prop in styles) {
        const value = styles[prop];
        const transformFn = transforms[prop];

        if (Array.isArray(value)) {
            for (let index = 0; index < value.length; index++) {
                const mediaValue = value[index];

                if (mediaValue === null || mediaValue === undefined || mediaValue === value[index - 1]) {
                    continue;
                } else if (index === 0) {
                    result = {
                        ...result,
                        ...(transformFn ? { ...transformFn(mediaValue) } : { [prop]: mediaValue }),
                    };
                } else {
                    const mediaKey = `@media screen and (min-width: ${breakpoints[index]}px)`;

                    result[mediaKey] = {
                        ...((result[mediaKey] as any) || {}),
                        ...(transformFn ? { ...transformFn(mediaValue) } : { [prop]: mediaValue }),
                    };
                }
            }
        } else if (transformFn) {
            result = { ...result, ...transformFn(value) };
        } else if (typeof value === 'object') {
            result = { ...result, [prop]: transformStyleObject(value as StyleObject) };
        } else {
            result[prop] = value;
        }
    }

    return result;
};

const styled = createStyled({
    getInitialStyle,
    driver: (style: StyleObject, engine: any): string => driver(transformStyleObject(style), engine),
    wrapper: Component => Component,
});

const useStyletron = () => {
    const [css] = useDefaultStyletron();

    return [(style: StyleObject) => css(transformStyleObject(style))];
};

export { styled, useStyletron, withStyleDeep, withTransform, withWrapper };
