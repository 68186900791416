// Content Types
import Page from '../templates/page'
import Category from '../templates/category'
import Course from '../templates/product'
import Speaker from '../templates/speaker'
import Speakers from '../templates/speakers'

// Moduler
import Hero from "../modules/Hero";
import BigHero from "../modules/Hero/BigHero";
import BigTextHero from "../modules/Hero/BigTextHero";
import CourseHero from "../modules/Hero/ProductHero";

import Book from "modules/Book";
import Contact from "modules/Contact";
import Courses from "../modules/ProducList";
import FAQ from "../modules/FAQ";
import Guide from "../modules/Guide";
import Ingress from "../modules/Ingress";
import VideoBlock from "../modules/Video";
import List from "../modules/List";
import Slider from "../modules/Slider";
import Schedule from "../modules/Schedule";
import Marquee from "../modules/Marquee";
import Newsletter from '../modules/Newsletter';
import Slideshow from "modules/Slideshow";
import Gallery from "modules/Gallery";
import SpeakersGallery from "modules/SpeakersGallery";
import Collaborations from "modules/Collaborations";
import TextSlideshow from "modules/TextSlideshow";
import TextBlock from "../modules/Text";
import TextColumns from "modules/Text/TextColumns";
import TextSlider from "modules/TextSlider";
import Filter from "modules/Filter";

// Global Components
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

// Content Components
import NavItem from "./nav_item";

// Content Components
import Text from "./text";
import Image from "./image";
import Link from "./link";

import ComponentNotFound from "./component_not_found";

const ComponentList = {
  // Page types
  page: Page,
  course: Course,
  category: Category,
  speakers: Speakers,
  speaker: Speaker,

  // Global content types
  header: Header,
  footer: Footer,

  // Heros
  hero: Hero,
  hero_big: BigHero,
  hero_big_text: BigTextHero,
  courseHero: CourseHero,

  // Modules
  book: Book,
  contact: Contact,
  guide: Guide,
  faq: FAQ,
  ingress: Ingress,
  video_block: VideoBlock,
  courses: Courses,
  list: List,
  schedule: Schedule,
  slider: Slider,
  marquee: Marquee,
  newsletter: Newsletter,
  slideshow: Slideshow,
  gallery: Gallery,
  speakers_gallery: SpeakersGallery,
  collaborations: Collaborations,
  text_block: TextBlock,
  text_slideshow: TextSlideshow,
  text_slider: TextSlider,
  text_columns: TextColumns,
  filter: Filter,


  // Components
  nav_item: NavItem,

  // Content components
  text: Text,
  image: Image,
  link: Link,
};

const Components = (type) => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
