import React, { useRef } from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Fade from 'react-reveal/Fade';
import ModuleWrapper from '../../components/ModuleWrapper';
import TextSlide from './TextSlide';
import SlideBar from "../../components/SlideBar";

const Wrapper = styled("div", {
    marginBottom: "4rem",

    [media.min.tablet]: {
        marginBottom: "5rem"
    }, 

    borderTop: "0.8px solid #c6c6c6"
});

const Title = styled("h2", {
    fontSize: "1.875rem",
    lineHeight: "2.125rem",
    margin: "1rem 0  1rem",

    [media.min.tablet]: {
        margin: "1rem 0 2.25rem 2rem",
    },

    [media.min.maxWidth]: {
        margin: "1rem 0 2.25rem 4rem",
    }
});

const Slides = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "5rem 1rem 1rem 1rem",
    overflowX: "auto",
    "::-webkit-scrollbar": {
        backgroundColor: "#fff",
        color: "#fff"
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",

    [media.min.tablet]: {
        padding: "5rem 1rem 1rem 2rem",
    },

    [media.min.maxWidth]: {
        padding: "5rem 1rem 1rem 4rem",
    }
});

const Slider = ({ blok }) => {
    const slideRef = useRef();

    return (
        <ModuleWrapper>
            <Fade>
                <Wrapper>
                    <Title>{blok.title}</Title>
                    <Slides ref={slideRef}>
                        {blok.slides.map((blokItem, index) =>
                            React.createElement(TextSlide, {
                                key: blokItem._uid,
                                blok: blokItem,
                                index: index,
                                length: blok.slides.length,
                            })
                        )}
                    </Slides>
                    <SlideBar
                        slideRef={slideRef}
                    />
                </Wrapper>
            </Fade>
        </ModuleWrapper>
    )
};

export default Slider;