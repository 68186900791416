const colors = {
    black: '#000000',
    white: '#fff',
    error: '#D25108',
    grey: {
        dark: '#7e7e7e',
        light: '#C6C6C6'
    },
    green: {
        one: '#ADB1A4',
        two: '#616C5F',
        three: '#253129',
    }
};

export default colors;