import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";

const Item = styled("div", { 
  padding: "1.25rem 0",
  fontSize: "1rem",
  borderBottom: "1px solid #000",
  display: "flex",
  alignItems: "center",

  [media.min.tablet]: {
    fontSize: "1.125rem",
  }
})

const Icon = styled("div", { 
  width: "37.5%",

  [media.min.tablet]: {
    width: "20.666666666%",
    marginRight: "7.75rem"
  },
})

const Text = styled("div", { 
  lineHeight: "1.25rem",
  width: "62.5%",

  [media.min.tablet]: {
    width: "83.333333334%",
    lineHeight: "1.5rem"
  },
  
  

})

const ListItem = ({ blok, index }) => {

  return (
    <Item>
      <Icon>
        {blok.time}
      </Icon>
      <Text>
        {blok.text}
      </Text>
    </Item>
  )
};

export default ListItem;