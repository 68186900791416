import React from "react";
import PropTypes from 'prop-types';
import { asArray } from 'helpers/array';
import { styled } from 'libs/styletron';

const FlexibleAspectWrapper = styled('div', {
    // Style for flexible AspectWrapper
    // This style will allow the component to have a fixed ratio, but if the content dosen't fit that ratio it will allow the element to grow
    display: 'flex',
    alignItems: 'stretch',
    position: 'relative',
    // ::before and ::after will make the wrapper have a ratio, but if the content need more space the height is flexible
    '::before': {
        content: '""',
        float: 'left',
    },
    '::after': {
        display: 'table',
        content: '""',
        clear: 'both',
    },
});

const InnerFlexible = styled('div', {
    // This will make sure that the content is positioned properly
    width: '100%',
    height: 'auto',
});

const FixedAspectWrapper = styled('div', {
    // Style for fixed AspectWrapper
    // This style will set the element to a fixed ratio that can't be changed by its content
    position: 'relative',
    overflow: 'hidden',
    // ::before the wrapper have a ratio
    '::before': {
        display: 'block',
        content: '""',
        width: '100%',
    },
});

const InnerFixed = styled('div', {
    // This will make sure that the content is positioned properly
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '100%',
});

/**
 * AspectWrapper - A wrapper that creates a component with a fixed ratio.
 * @author Anton Pedersen
 *
 * @param {node} children - Child components
 * @param {boolean} flexibleHeight - If true, the wrapper can adjust height if content doesn't fit
 * @param {number} ratio - The ratio used by the wrapper
 * @param {object} rest - Spread remaning props to wrapper, used to add class, styles etc.
 */

const AspectWrapper = ({ children, flexibleHeight, ratio, $style, ...rest }) => {
    const style = {
        ...$style,
        '::before': {
            paddingBottom: asArray(ratio).map(r => `${(100 / r).toFixed(2)}%`),
        },
    };

    // FlexibleAspectWrapper will allow the AspectWrapper to adjust its height if the content dosen't fit
    return flexibleHeight ? (
        <FlexibleAspectWrapper $style={style} {...rest}>
            <InnerFlexible>{children}</InnerFlexible>
        </FlexibleAspectWrapper>
    ) : (
        // FixedAspectWrapper always have the same ratio, even if the content doesn't fit
        <FixedAspectWrapper $style={style} {...rest}>
            <InnerFixed>{children}</InnerFixed>
        </FixedAspectWrapper>
    );
};

AspectWrapper.propTypes = {
    $style: PropTypes.object,
    children: PropTypes.node,
    flexibleHeight: PropTypes.bool,
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number]))]),
};

AspectWrapper.defaultProps = {
    $style: {},
    children: null,
    flexibleHeight: false,
    ratio: 3 / 1,
};

export default AspectWrapper;
