import React from "react";
import { styled } from 'libs/styletron';
import Step from './Step';
import { media } from "config/theme";
import DefaultWrapper from "components/layouts/DefaultWrapper";

const Wrapper = styled("div", {
    [media.min.tablet]: {
        marginLeft: "1rem"
    },
});

const Title = styled("h2", {
    fontSize: "1.875rem",
    lineHeight: "2.125rem",
    marginBottom: "6rem",

   
});

const Steps = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",

    padding: "2rem 0",
    overflowX: "auto",
});

const Guide = ({ blok }) => blok ? (

    <DefaultWrapper border="light">
        <Wrapper>
            <Title>{blok.tag}</Title>

            <Steps>
                {blok.steps.map((blok, index) =>
                    React.createElement(Step, {
                        key: blok._uid,
                        blok: blok,
                        index: index
                    })
                )}
            </Steps>
        </Wrapper>
    </DefaultWrapper>
) : null;

export default Guide;