import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import BlokImage from "components/BlokImage";
import AspectWrapper from "components/AspectWrapper";
import Button from 'components/buttons/Button';
import Fade from 'react-reveal/Fade';

const Item = styled("div", props => ({
    position: 'relative',
    alignItems: "center",
    marginBottom: "3rem",
    width: "100%",
    flexShrink: "0",

    marginRight: props.$mobileSlider ? "1rem" : "0",
    maxWidth: props.$mobileSlider ? "300px" : "none",

    [media.min.tablet]: {
        width: "calc(50% - 1.5rem)",
        padding: "0 0.75rem 0 0.75rem",
        marginBottom: "6.5rem",
        maxWidth: "none",
        marginRight: "0"
    },

    [media.min.laptop]: {
        width: "calc(33.3333% - 1.5rem)",
    },

    [media.min.desktop]: {
        width: "calc(25% - 1.5rem)",
    },
}));

const Link = styled("a", ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [media.min.tablet]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    [media.min.laptop]: {
        justifyContent: "flex-start"
    }

}));

const FirstDate = styled("div", {  
    position: "absolute",
    top: "1rem",
    left: "1rem",
    textTransform: "uppercase",
    zIndex: "1",
    color: `${colors.white}`,
    fontSize: "1.125rem",
    lineHeight: "1.375rem"
})

const Info = styled("div", { 

    [media.min.tablet]: {
        // paddingBottom: "5rem"
        paddingBottom: "0"
    },

    [media.min.laptop]: {
        paddingBottom: "5rem"
    },
});

const Text = styled("div", { 
    
    marginTop: "1rem",

    [media.min.laptop]: {
        marginTop: 0,
        maxWidth: "400px",
        justifyContent: "flex-start",
    },
});

const Image = styled("div", { 
    position: "relative",
    marginBottom: "1.125rem",

    [media.min.tablet]: {
        
    }
});

const Tag = styled("div", {
    fontSize: "1rem",
    lineHeight: "1.25rem",
    marginBottom: "0.25rem",
    letterSpacing: "0.03em",
});

const Type = styled("span", props => ({
    textTransform: "uppercase",
    paddingRight: props.$short ? "0" : "10px",
    display: props.$shor ? "block" : "inline",

}));

const Divider = styled("span", props => ({
    display: props.$short ? "none" : "inline",
}));

const Topic = styled("span", props => ({
    paddingLeft: props.$short ? "0" : "10px",
    display: props.$short ? "block" : "inline",
    textTransform: "capitalize",

}));

const Title = styled("div", props => ({
    fontSize: "1.5625rem",
    lineHeight: "2.125rem",
    marginBottom: "2px",
    minHeight: props.$mobileSlider ? "68px" : "0",

    [media.min.tablet]: {
        minHeight: "0"
    },

    [media.min.desktop]: {
        fontSize: "1.875rem",
        lineHeight: "2.125rem",
    },
}));

const Speaker = styled("div", {

    fontSize: "1.875rem",
    lineHeight: "2.125rem",

    [media.min.desktop]: {
        fontSize: "1.875rem",
        lineHeight: "2.125rem",
    },

    marginBottom: "0.5rem",
    color: `${colors.grey.dark}`,
});



const Details = styled("div", { 
    display: "none",
    fontSize: "1rem",
    lineHeight: "1.25rem",

    [media.min.tablet]: {
        color: `${colors.grey.dark}`,
        display: "block"
    },

    [media.min.desktop]: {
        fontSize: "1.125rem",
        lineHeight: "1.375rem"
    }
});

const Arrow = styled('svg', {
    marginLeft: "0.5rem",
});

const ReadMore = styled(Button, props => ({
    marginTop: "1rem",
    justifyContent: "flex-end",
    display: props.$short ? "none" : "block",
    maxWidth: "150px",

    [media.min.laptop]: {
        maxWidth: "none",
        display: "block",
        marginTop: "0",
        position: "absolute",
        bottom: "0",
    },
}));


const GridItem = ({ blok, short, mobileSlider }) => {
    const course = blok.course;
    const occasions = blok.course.content.occacions;
    let locations = [];
    let firstDate = course.content.occacions && course.content.occacions.length > 0 && course.content.occacions[0].date;
    let date = undefined;

    const monthNames = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
    if (firstDate) {
        date = new Date(firstDate);
    }

    occasions !== "undefined" && occasions?.map((occasions, i) => {
        if (locations.includes(occasions.location) === false) locations.push(occasions.location);
    });

    return (
        <Item $mobileSlider={mobileSlider} $short={short} className="grid-item-wrapper">
            <Fade>
            <Link href={course.full_slug}>
                <Info>
                        <Image>
                            {/*date &&
                                <FirstDate>
                                    {date.getDay() + " " + monthNames[date.getMonth()] + ", " + date.getFullYear()}
                                </FirstDate>
                            */ }
                            <AspectWrapper ratio={3 / 2}>
                                <BlokImage
                                    wrapperStyle={{
                                        height: "100%",
                                        width: "100%",
                                        position: "relative",
                                    
                                    }}
                                    objectFit="cover"
                                    objectPosition="50% 50%"
                                    alt={blok.title}
                                    fluid={{}}
                                    blok={course.content && course.content.hero[0].image}
                                />
                            </AspectWrapper>
                        </Image>
                    <Text>
                        <Tag>
                            <Type $short={short}>{course.content.type === 'workshop' ? 'Workshop' : course.content.type === 'lecture' ? 'Föreläsning' : 'Kurs'}</Type>
                            <Divider $short={short}>|</Divider>
                            <Topic $short={short}>{course.content.topic}</Topic>
                        </Tag>
                        <Title $mobileSlider={mobileSlider}>{course.name}</Title>
                        {!short && 
                            <React.Fragment>
                                <Speaker>
                                    {course.content.speakers.length > 1 && "Flera talare" }
                                    {course.content.speakers.length === 1 && 
                                        <span>{course.content.speakers[0].name}</span>
                                    }
                                </Speaker>
                                <Details>
                                    {locations.map((location, i) => 
                                        <span>{location}{i + 1 !== locations.length ? ", " : ""}</span>
                                    )}
                                    <br />
                                    {course.content.cost } SEK exkl. moms
                                </Details>
                            </React.Fragment>
                        }
                    </Text>
                </Info>
                <ReadMore color="#000" $short={short}>
                    Läs  Mer
                    <Arrow width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 10V0.999999H0.000273854" stroke="black"/>
                        <line y1="-0.5" x2="12.0335" y2="-0.5" transform="matrix(-0.712994 0.70117 -0.701148 -0.713016 8.57983 1)" stroke="black"/>
                    </Arrow>
                </ReadMore>
            </Link>
          </Fade>
        </Item>
    )
}   

export default GridItem;