import React, { useState } from "react";
import { styled } from 'libs/styletron';
import SbEditable from "storyblok-react";
import { media } from "config/theme";
import { PlayIcon2 } from 'components/ui/icon/icon'
import { InView } from 'react-intersection-observer'


const Video = styled('video', {
  objectFit: "cover",
  width: "100%",
  height: "100%",
  maxWidth: "100%"
});

const VideoWrapper = styled('div', {
  position: "relative",
  background: "#000",
  marginBottom: "5rem",
  height: "auto",

  [media.min.laptop]: {
    marginBottom: "7.5rem",
  },
  
  [media.min.dekstop]: {
    marginBottom: "9rem",
  }
  
});

const Overlay = styled('div',{
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  display: "flex",
  cursor: "none"
});

const Poster = styled('img', {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  display: "block",
  opacity: "1",
  transition: "opacity 0.5s",
});

const PlayWrapper = styled('div', props => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  zIndex: "1",
  width: "auto",
  height: "auto",
  top: "50%",
  left: "50%",
  marginLeft: "-50px",
  marginTop: "-20px",
  pointerEvents: "none",
  cursor: "none",

  [media.min.tablet]: {
    display: props.$display ? "flex" : "none",
    top: props.$top,
    left: props.$left,
    marginLeft: "0",
    marginTop: "0",
    width: "253px",
    height: "108px",
  }

}));

const Play = styled(PlayIcon2, props =>  ({
  zIndex: "1",
  width: "25px",
  height: "37.5px",

  [media.min.tablet]: {
    width: "50px",
    height: "75px",
  } 
}));

const PlayText = styled('p', {
  fontSize: "3.125rem",
  color: "#ffffff",
  marginRight: "10px",
  [media.min.tablet]: {
    fontSize: "6.375rem",
    marginRight: "20px"
  }
});





class VideoBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoStarted: false,
      player: null,
      shown: false,
      left: 0,
      top: 0,
      playDisplay: false
    }
  }

  onVideoReady = event => {
    this.setState({
      player: event.target,
    })
  }

  PlayVideo = () => {
    this.state.player.play();

    // if mobile open video in fullscreen
    if (window) {
      if (window.innerWidth < 1023) {
        this.state.player.webkitEnterFullscreen();
      }
    }

    this.setState({
      videoStarted: true,
    })
  }

  onChangeView(inView) {
    if (inView && !this.state.shown) {
      this.setState({ shown: true });
    }
  }

  onPauseVideo = () => {
    // this.setState({
    //  videoStarted: false,
    // })
  }

  handleMouseMove = ev => {
    this.setState({
      left: `${ev.nativeEvent.offsetX - 100}px`,
      top: `${ev.nativeEvent.offsetY - 50}px`
    });
  }

  setPlayDisplay = (ev, bool) => {
    this.setState({
      playDisplay: bool
    });
  }

  render() {
    const { blok } = this.props;
    const { shown } = this.state;

    if (blok) {
      return (
        <SbEditable content={blok}>
          <InView as="div" onChange={(inView) => this.onChangeView(inView)}>
              <VideoWrapper>
                {blok.video_mobile &&
                  <Video
                    controls={blok.poster}
                    muted={!blok.poster}
                    autoPlay={!blok.poster}
                    loop={!blok.poster}
                    playsInline={!blok.poster}
                    onCanPlay={this.onVideoReady}
                    onPause={this.onPauseVideo}

                    className="mobile"
                  >
                    <source src={blok.video_mobile} type="video/mp4" />
                  </Video>

                }

                <Video
                  controls={blok.poster} muted={!blok.poster}
                  autoPlay={!blok.poster} loop={!blok.poster}
                  playsInline={!blok.poster} onCanPlay={this.onVideoReady}
                  onPause={this.onPauseVideo}

                  className="desktop"
                >
                  <source src={blok.video} type="video/mp4" />
                </Video>



                {blok.poster && <Overlay
                  px={['16px', null, null, '32px']}
                  py={['16px', null, null, '48px']}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  onClick={this.PlayVideo}
                  className={this.state.videoStarted ? 'poster hidden' : 'poster'}
                  onMouseMove={ev => this.handleMouseMove(ev)}
                  onMouseEnter={ev => this.setPlayDisplay(ev, true)}
                  onMouseLeave={ev => this.setPlayDisplay(ev, false)}
                >
                  <PlayWrapper $display={this.state.playDisplay} $top={this.state.top} $left={this.state.left}>
                    <PlayText>Play</PlayText>
                    <Play width={50} height={75} color="#ffffff" />
                  </PlayWrapper>

                  {blok.poster && <Poster src={blok.poster} alt={blok.label} />}
                </Overlay>
                }
              </VideoWrapper>
          </InView>
        </SbEditable>
      )
    } else {
      return null
    }
  }
}

export default VideoBlock