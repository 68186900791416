import React from "react";
import Link from "gatsby-link";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";

const Item = styled('li', {
    listStyle: "none",
    display: "inline-block",
    width: "auto",
    padding: "0.5rem 1rem",
    margin: "0.25rem 0",
    letterSpacing: "0.03em",
    fontSize: "2rem",

    [media.min.laptop]:  {
      fontSize: "1rem",
    }
});

const LinkToUrl = (link) => (
  (link.linktype === "url" ? "" : "/")  + (link.cached_url === "home" ? "" : link.cached_url)
);

const NavItem = ({ blok, onLink }) => (
  <SbEditable content={blok}>
    <Item className="nav-item active">
      
      { (blok.link.linktype === "url" ? 
      
        <a  
          clas="nav-link"
          href={ LinkToUrl(blok.link) } 
        >
          {blok.text}
        </a>
        
      :

        <Link
          className="nav-link"
          to={ LinkToUrl(blok.link) }
          onClick={onLink}
        >
          {blok.text}
        </Link>
      )}

    </Item>
  </SbEditable>
);

export default NavItem;
