import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Column from "components/layouts/Column";
import Button from 'components/buttons/Button';
import DefaultWrapper from "components/layouts/DefaultWrapper";
import Form from "components/Form/DefaultForm";

const Title = styled("div", {
    fontSize: "1.875rem",
    marginBottom: "1rem",

    [media.min.laptop]: {
        fontSize: "3rem",
        lineHeight: "3rem",
    },
});

const Text = styled("p", {
    marginBottom: "1rem",
    maxWidth: "75%",

    [media.min.tablet]: {
        fontSize: "1.125rem",
        lineHeight: "130%",
        letterSpacing: "0.02em",
    },
});

const Email = styled("a", {
    [media.min.tablet]: {
        fontSize: "1.125rem",
        lineHeight: "130%",
        letterSpacing: "0.02em",
    },
})

const BookButton = styled(Button, {
    display: "block",
    marginBottom: "2rem",
});

const Book = ({ blok }) => {
    const formRef = React.createRef();

    function handleClick() {
        formRef.current.toggleForm();
    }

    return (
        <>
        <DefaultWrapper layout="horizontal" columns={true}>
            <Column space="4">
                <Title>{blok.title}</Title>
            </Column>
            <Column space="4">
                <Text>{blok.text}</Text>
                <BookButton onClick={handleClick} color="#000">
                    Boka oss
                </BookButton>
                <Email href={"mailto:" + blok.email}>{blok.email}</Email>
            </Column>
            <Column space="4">
                
            </Column>
        </DefaultWrapper>
        <Form
            title="Boka oss"
            ref={formRef}
        />
        </>
    )
};

export default Book;