import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import SbEditable from "storyblok-react";
import { autoComposeDeep } from "styletron-react";

const getColor = props => {
  let color = `'${colors.white}'`;
  if (props.theme === 'white_black') color = `${colors.white}`;
  if (props.theme === 'black_white') color = `${colors.black}`;
  return color;
};

const getBackground = props => {
  let color = `'${colors.white}'`;
  if (props.theme === 'black_white') color = `${colors.white}`;
  if (props.theme === 'white_black') color = `${colors.black}`;
  return color;
};

const HeroWrapper = styled("div", props => ({
  height: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  color: `${getColor(props)}`,
  backgroundColor: `${getBackground(props)}`,
  padding: "0 1rem 1rem 1rem",


  [media.min.tablet]: {
    padding: "0 24px 24px 24px",
  },

  [media.min.laptop]: {
    padding: "107px 32px 32px 32px",
    marginBottom: "64px"
  },

  [media.min.desktop]: {
    padding: "107px 72px 72px 72px",
  }
}));

const Title = styled("span", props => ({
  position: "relative",
  fontSize: "3.125rem",
  marginTop: "280px",
  display: "flex",
  flexDirection: "column",

  [media.min.tablet]: {
    flexDirection: "row",
    fontSize: "13vw",
    margin: "0 auto",
    marginTop: "260px",
  }
}));

const TitleHigh = styled("span", {
  lineHeight: "100%"
});

const TitleLow = styled("span", {
  display: "block",
  lineHeight: "100%",

  [media.min.tablet]: {
    lineHeight: "100%"

  },
  [media.min.desktop]: {
    top: "105px",
    left: "-1rem",
  }
});

const TitleLowWrapper = styled("span", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",

  [media.min.tablet]: {
    top: "9vw",
    left: "-1rem",
  }
});

const Content = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  marginBottom: "1.75rem",
  marginTop: "1rem",

  [media.min.tablet]: {
    maxWidth: "50vw",
    marginBottom: "8rem",
    marginTop: "0"
  },

  [media.min.laptop]: {
    marginBottom: "4rem",
  },
});

const Tag = styled("p", props => ({
  fontSize: "1.125rem",
  lineHeight: "1.5rem",
  paddingBottom: "1.125rem",
}));


const Ingress = styled("p", props => ({
  fontSize: "1.125rem",
  lineHeight: "1.5rem",
  padding: "1.125rem 0",
  lineHeight: "150%",
}));



const BigTextHero = ({ blok }) => (
  <SbEditable content={blok}>
    <HeroWrapper theme={blok.theme}>
        <Title>
          <TitleHigh>{blok.header1}</TitleHigh>
          <TitleLowWrapper>
            <TitleLow>{blok.header2}</TitleLow>
              <Content>
                <Tag>{blok.tag}</Tag>
                <Ingress>{blok.ingress}</Ingress>
              </Content>
          </TitleLowWrapper>
        </Title>

    </HeroWrapper>
  </SbEditable>
);

export default BigTextHero;