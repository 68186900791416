import React from 'react'
import SbEditable from 'storyblok-react'
import { styled } from 'libs/styletron';
import { media } from "config/theme";


const ImageContent = styled('img', {
  width: "100%",

  [media.min.laptop]: {
    width: "60%"
  },

  [media.min.desktop]: {
    width: "60%"
  } 
});

const Image = ({blok}) => (
  <SbEditable content={blok}>
    <ImageContent src={blok.image} alt="" />
  </SbEditable>
)

export default Image
