import React, {Fragment, useEffect} from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import setTheme from 'helpers/setTheme';
import ProductGrid from 'modules/ProductGrid';
import DefaultWrapper from "components/layouts/DefaultWrapper";
import Filter from "modules/Filter"
import MetaData from 'components/Metadata';


const HeroCategory = styled("div", props => ({
    marginTop: "4rem",
    padding: "1rem 0",
    display: "flex",
  
    [media.min.tablet]: {
        padding: "1.5rem 0",
    },
  
    [media.min.laptop]: {
        padding: "2rem 0",
        paddingTop: "7.5rem",
    },

    [media.min.desktop]: {
        paddingLeft: "0",
        paddingRight: "0",
    }
}));

const Title = styled("h1", {
    fontSize: "3rem",
    display: "inline",
    padding: "0",
  
    [media.min.laptop]: {
        //fontSize: "4.5rem",
        fontSize: "4.25vw"
    },
});

const Count = styled("sup", props => ({
    fontSize: "1.5625rem",
    marginLeft: "0.5rem",
  
    [media.min.tablet]: {
    },
  
    [media.min.laptop]: {
        fontSize: "1.875rem"
    },
}));


const Category = props => {
    const {courses, name, full_slug, blok} = props; 
    const {metadata} = blok;
    let seoMeta, seoImage, overrideTitle;
    
    if (metadata && metadata.title) {
        overrideTitle = metadata.title;
    }

    if (metadata && metadata.description) {
        seoMeta = metadata.description;
    }

    useEffect(() => {
        setTheme('light');
        window.scrollTo(0,0);
      }, []);


    let course = courses?.map((blok, index) => (
        {
            _uid: "key", 
            space: index === 0 ? "4" : "2", 
            course: {
                name: blok.name,
                full_slug: blok.url,
                tag_list: [
                    "Varumärke & Strategi"
                ],
                content: {
                    hero: [
                            {
                                Text: blok.text,
                                image: blok.image
                            }
                    ],
                    type: blok.type,
                    topic: blok.topic,
                    courseLocation: blok.courseLocation,
                    cost: blok.cost,
                    speakers: blok.speakers,
                    occacions: blok.occacions
                }
            }, 
            component: "course_item"
        }
    ));

    let courseBlock = {course};
    let count = courses ? courses.length : 0;
    
    return (
        <Fragment>
            <MetaData
                type="website"
                seoTitle={name}
                url={full_slug}
                overrideTitle={overrideTitle}
                seoMetaDescription={seoMeta}
                seoOgimage={seoImage}
            />
        
            <DefaultWrapper border="none">
                <HeroCategory>
                    <Title>Utbildningar</Title>
                    <Count>{count}</Count>
                </HeroCategory>
                <Filter></Filter>
                <ProductGrid blok={courseBlock} />
            </DefaultWrapper>
        </Fragment>
    );
};

export default Category;