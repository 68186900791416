import React, {Fragment, useState } from "react"; 
import Slide from './Slide';
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderWrapper from "react-slick";
import Fade from 'react-reveal/Fade';
import { Above,Below } from 'components/Breakpoints';
import Slider from 'modules/Slider';

const Wrapper = styled("div", { 
    position: "relative",
    marginBottom: "4rem",
    
    [media.min.laptop]: {
        marginBottom: "0rem"
    }  
})

const NextArrow = (props) => {
    return (
        <button className="slick-next slick-arrow" onClick={props.onClick}  type="button">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.48657 17.9708L16.9719 9.48554L8.48657 1.00026" stroke="black" stroke-width="1.5"/>
                <line x1="16.5694" y1="9.83933" x2="0.525104" y2="9.70493" stroke="black" stroke-width="1.5"/>
            </svg>
        </button>
    )
};

const PrevArrow = (props) => {
    return (
        <button className="slick-prev slick-arrow"  onClick={props.onClick}  type="button">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4853 1L2.00001 9.48528L10.4853 17.9706" stroke="black" stroke-width="1.5"/>
                <line x1="2.40246" y1="9.1315" x2="18.4468" y2="9.26589" stroke="black" stroke-width="1.5"/>
            </svg>
        </button>
    )
};


const SlideTrackContent = styled("p", props =>  ({
    fontSize: "1rem",
    position: "Absolute",
    left: "1rem",
    bottom: props.$pos === true ? "0rem" : "-3rem",

    [media.min.tablet]: {
        bottom: props.$pos === true ? "2rem" : "0rem",
        left: "2rem"
    },
    [media.min.laptop]: {
        bottom: props.$pos === true ? "3rem" : "1rem",
    },
    [media.min.maxWidth]: {
        left: "4rem"
    }
}));

const Slideshow = ({ blok }) => {
    const [slidesState, setSlidesStates] = useState({oldSlide: 0, activeSlide: 0 })

    let settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        draggable: true,
        beforeChange: (current, next) => setSlidesStates({oldSlide: current, activeSlide: next}),
    };
    let currentSlide = (slidesState.activeSlide < 10) ? `0${slidesState.activeSlide + 1}` : (slidesState.activeSlide + 1);
    let slideTrack = (blok.slides.length < 10) ? '—0' : "—";
    let wrapperClass = blok.slides[0].title.trim() === "" ? "slideshow-small" : "slideshow-large";
    let slideTrackPos = blok.slides[0].title.trim() === "" ? true : false;

    const slidesList = blok.slides;

    let slides = slidesList.map((b) => (
        {
            description: b.description,
            link: {
                url: b.link.url !== "" ? b.link.url : b.link.cached_url
            },
            image: {
                filename: b.media.filename
            },
            title: b.title
        }
    ));

    let slideBlok = {
        slides: slides,
    }

    return (
        <Fragment>
            <Below breakpoint="md">
                <Slider blok={slideBlok}></Slider>
            </Below>
            <Above breakpoint="md">
                <Wrapper className={wrapperClass}>
                    <Fade>
                        <SliderWrapper {...settings}>
                                {blok.slides.map(blok =>
                                    React.createElement(Slide, {
                                        key: blok._uid,
                                        blok: blok,
                                    })
                                )}
                        </SliderWrapper>
                        <SlideTrackContent className="slide-track-content" $pos={slideTrackPos} >{currentSlide + slideTrack + blok.slides.length}</SlideTrackContent>
                        </Fade>
                </Wrapper>
            </Above>
        </Fragment>
    )
} 

export default Slideshow;