import React from "react";
import GalleryItem from './Speaker';
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Link from "../../components/buttons/Link";
import Fade from 'react-reveal/Fade';
import DefaultWrapper from "components/layouts/DefaultWrapper";



const Below = styled("div", { 
 
    marginBottom: "2rem",
    [media.min.laptop]:{
        marginBottom: "7rem",
    }
});

const GalleryWrapper = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflowX: "auto",
    "::-webkit-scrollbar": {
        backgroundColor: "#fff",
        color: "#fff"
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    [media.min.tablet]: {
        display: "grid",
        gridTemplateColumns: "repeat(26, 1fr)",
        gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr 0.6fr 1fr 1fr 1fr 1fr 1fr 1fr",

    },

    marginBottom: "3.5rem",
    [media.min.maxWidth]: {
        marginBottom: "7rem",
    }
});

const Title = styled("h2", {
    fontSize: "1.875rem",
    paddingBottom: "2.25rem",

    [media.min.laptop]: {
        fontSize: "3rem",
    },
    
    [media.min.maxWidth]: {
        paddingBottom: "5rem"
    }
});

const Actions = styled("div", { 
    textAlign: "center"
});

const SpeakersGallery = ({ blok }) => {
    return (
        <Fade>
            <DefaultWrapper border="none">
                <Title>{blok.title_text}</Title>
                <GalleryWrapper>
                        {blok.Speaker.map((blokItem, index) =>
                            React.createElement(GalleryItem, {
                                key: blok._uid,
                                blok: blokItem,
                                index: index,
                            })
                        )}
                </GalleryWrapper>
                <Actions >
                    <Link theme="light" href={blok.button_link.cached_url} >{blok.button_text}</Link>
                </Actions> 
            </DefaultWrapper>
            <Below></Below>
        </Fade>
    )
} 

export default SpeakersGallery;