import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Fade from 'react-reveal/Fade';

const Item = styled("div", props => ({  
  padding: "1.25rem 0",
  fontSize: "1.125rem",
  lineHeight: "24px",
  borderBottom: props.$design === "lines" ? "1px solid #000" : "none",
  // borderBottom: "1px solid #000",
  display: props.$layout === 'horizontal' ? 'block' : 'flex',
  alignItems: "center",

  [media.min.tablet]: {
    fontSize: props.$design === "lines" ? "1.125rem" : "1.75rem",
    // fontSize: "1.125rem" 
  }
}))

const Icon = styled("div", props => ({  
  display: props.$layout === 'horizontal' ? 'none' : 'block',
  width: "37.5%",

  [media.min.tablet]: {
    // width: "16.666666666%"
    width: "20.666666666%",
    marginRight: "7.75rem"
  },
}))

const Text = styled("div", props => ({ 
  lineHeight: "1.25rem",
  width: props.$layout === 'horizontal' ? '100%' : '62.5%',

  [media.min.tablet]: {
    lineHeight: "1.5rem",
    width: props.$layout === 'horizontal' ? '100%' : '83.333333334%',
  },
}))

const ListItem = ({ blok, layout, design }) => {

  return (
    <Fade>
      <Item $design={design}>
        
          <Icon $layout={layout}>
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.89941 20.7993L19.7989 10.8998L9.89941 1.00033" stroke="black" strokeWidth="1.5"/>
              <line x1="19.3306" y1="11.1875" x2="0.612285" y2="11.0307" stroke="black" strokeWidth="1.5"/>
            </svg>
          </Icon>
          <Text $layout={layout}>
            {blok.text}
          </Text>
       
      </Item>
    </Fade>
  )
};

export default ListItem;