import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import { labels, breakpoints, breakpointsMax } from "./breakpointValues";

 
const FirstDate = styled("div", {  
});

const AboveWrapper = styled("div",{
  [media.max.tablet]: {
    display: "none"
  }
});

const BelowWrapper = styled("div", {
  [media.min.tablet]: {
    display: "none"
  }
});



const Above = ({ breakpoint, children, ...props }) => (
  <AboveWrapper breakpoint={breakpoint} {...props}>
    {children}
  </AboveWrapper>
);
const Below = ({ breakpoint, children, ...props }) => (
  <BelowWrapper breakpoint={breakpoint} {...props}>
    {children}
  </BelowWrapper>
);

export { Above, Below };
