import React, { useEffect, useState, useRef } from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import Column from "components/layouts/Column";
import DefaultWrapper from "components/layouts/DefaultWrapper";
import { FilterArrow } from 'components/ui/icon/icon'
import FilterList from "./FilterList";
import RadioButton from "../../components/RadioButton";
import { Above,Below } from 'components/Breakpoints';
import MobileFilter from "./MobileFilter"

const MainWrapper = styled("div", {
    display: "none"
});

const DesktopWrapper = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: "0 1rem",
    [media.min.tablet]: {
        margin: "0 2rem",
    },
    [media.min.desktop]: {
        margin: "0 4rem",
    },
});

const Wrapper = styled("div", {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
});

const TitleWrapper = styled("div", {
    display: "flex",
    width: "35%",
    height: "fit-content"
});

const Title = styled("h2", {
    fontSize: "1.875rem",
    lineHeight: "2.125rem"
});

const ArrowWrapper = styled("button", props => ({
    height: "fit-content",
    margin: "6px 0 0 15px",
    transform: props.$open ? "rotate(0deg)" : "rotate(180deg)",
    // transition: "transform 0.3s ease-in-out"
}));

const Arrow = styled(FilterArrow, {

});

const Filter = styled("div", {
    display: "flex",
    flexDirection: "column",
    width: "65%",
    alignItems: "flex-end",
});

const Top = styled("div", {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    borderTop: "0.5px solid #000000",
    borderBottom: "0.5px solid #000000",
    padding: "1rem 0"
});

const TopText = styled("div", {
    width: "20%"
})

const ListWrapper = styled("div", props => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: props.$open ? "100%" : "0",
    width: "100%",
    overflow: "hidden",
    // transition: "height 0.3s ease-in-out"
}));

const Bottom = styled("div", props => ({
    display: "flex",
    width: "100%",
    borderTop: props.$open ? "0.5px solid #000000" : "none",
    borderBottom: "0.5px solid #000000",
    padding: "1rem 0"
}));

const RadioButtonWrapper = styled("div", {
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem"
})

const RadioText = styled("span", {
    marginLeft: "-0.5rem"
});

const Text = styled("span", props =>  ({
    color: props.$color === "grey" ? "#757575" : "#000000",
    margin: `${props.$margin}`
}));

const BottomWrapper = styled("div", {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "1.875rem"
});

const ActiveFilter = styled("div", {

});

const EventCount = styled("div", {

});

const TestUL = styled("ul", {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    paddingInlineStart: "0px",
    width: "20%",
});

const TestLI = styled("li", {
    width: "100%"
});


const List = ({ blok }) => {
    const [toggleFilter, setToggleFilter] = useState(false);
    return (
        <MainWrapper>
            <Above>
                <DesktopWrapper>
                    <Wrapper>
                        <TitleWrapper>
                            <Title>Filter</Title>
                            <ArrowWrapper 
                                $open={toggleFilter} 
                                onClick={() => setToggleFilter(!toggleFilter)} 
                                className="filter-arrow">
                                <Arrow width={22} height={22} color="#000000" />
                            </ArrowWrapper>
                        </TitleWrapper>
                        <Filter>
                            <Top>
                                <TopText>Topics</TopText>
                                <TopText>Type</TopText>
                                <TopText>Location</TopText>
                            </Top>
                            <ListWrapper $open={toggleFilter}>
                                <TestUL>
                                    <TestLI>Content marketing</TestLI>
                                    <TestLI>SEO optimization</TestLI>
                                    <TestLI>Brand & strategy</TestLI>
                                    <TestLI>Shopify</TestLI>
                                    <TestLI>UX</TestLI>
                                    <TestLI>Innovation</TestLI>
                                </TestUL>
                                <TestUL>
                                    <TestLI>Course</TestLI>
                                    <TestLI>Lecture</TestLI>
                                    <TestLI>Workshop</TestLI>
                                </TestUL>
                                <TestUL>
                                    <TestLI>Online</TestLI>
                                    <TestLI>Stockholm</TestLI>
                                    <TestLI>Gothenburg</TestLI>
                                    <TestLI>Malmö</TestLI>
                                    <TestLI>Copenhagen</TestLI>
                                </TestUL>
                                {/* {blok.x.map(blok =>
                                    React.createElement(FilterList, {
                                        key: blok._uid,
                                        blok: blok,
                                    })
                                )} */}
                            </ListWrapper>
                            <Bottom $open={toggleFilter}>
                                <Text>Sort by:</Text>
                                <RadioButtonWrapper>
                                    <RadioButton
                                        />
                                    <RadioText>Date</RadioText>
                                </RadioButtonWrapper>
                                <RadioButtonWrapper>
                                    <RadioButton
                                        />
                                    <RadioText>Popularity</RadioText>
                                </RadioButtonWrapper>
                                <RadioButtonWrapper>
                                    <RadioButton
                                        />
                                    <RadioText>News</RadioText>
                                </RadioButtonWrapper>
                                <RadioButtonWrapper>
                                    <RadioButton  />
                                    <RadioText>Speaker (A-Z)</RadioText>
                                </RadioButtonWrapper>
                            </Bottom>
                        </Filter>
                    </Wrapper>
                    <BottomWrapper>
                        <ActiveFilter>
                            <Text>Active filter:</Text>
                            <Text $margin="0 0 0 12px" $color="grey" >No active filters</Text>
                        {/* check if active filter */}
                        </ActiveFilter>
                        <EventCount>
                            <Text $color="grey" >26 events</Text>
                        </EventCount>
                    </BottomWrapper>
                </DesktopWrapper>
            </Above>
            <Below>
                {React.createElement(MobileFilter, {
                    blok: blok
                })}
            </Below>
        </MainWrapper>

    );
};

export default List;