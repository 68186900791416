import React, { Fragment } from "react";
import { styled } from 'libs/styletron';
import { media } from "../../config/theme";
import Speaker from './Speaker';
import DefaultWrapper from "components/layouts/DefaultWrapper";

const Wrapper = styled("div", props => ({
    display: "flex",
    flexWrap: "wrap",
    // margin: "0 1rem",

    [media.min.tablet]: {
        // margin: "0 1rem 0 1rem",
        flexWrap: "nowrap",
        paddingRight: "2rem",
        marginLeft: "-0.5rem",
        paddingTop: "5rem"
    },

    [media.min.laptop]: {
        margin: "0 0rem",
        width: "calc(100% - 64px)"
    },
}));

const H2 = styled("div", props => ({
    fontSize: "2rem",
    margin: "0 1rem 5rem 0rem",
    
    [media.min.tablet]: {
        fontSize: "1.875rem",
        margin: "0 1rem 1rem 0rem",
    },
  
    [media.min.laptop]: {
        margin: "0 2rem 2rem 1rem",
    },
}));

const Speakers = ({ speakers }) =>  {
    return (
        <DefaultWrapper border="light" fullWidth={true}>
            <Fragment>
                <H2>Om {speakers.length > 1 ? "föreläsarna" : "föreläsarna" }</H2>
                <Wrapper>
                    {speakers?.map((blok, index) =>
                        React.createElement(Speaker, {
                            key: blok._uid,
                            blok: blok,
                            index: index,
                            length: speakers.length
                        })
                    )}
                </Wrapper>
            </Fragment>
        </DefaultWrapper>
    )
};


export default Speakers;