import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import BlokImage from "components/BlokImage";

const Container = styled("div", props => ({
    paddingRight: props.$length === (props.$index + 1) ? '0' : '1rem',
    paddingLeft: props.$index === 0 ? '0' : '1rem',
    [media.min.tablet]: {
        paddingRight: props.$length === (props.$index + 1) ? '0' : '4.5625rem',
        paddingLeft: props.$index === 0 ? '0' : '4.5625rem',
    }
}));

const ImageLink  = styled("a", props => ({
    position: "relative",
    display: "block",
    height:
    props.$index % 2 === 0 ? "316px" : 
    (props.$index % 2 !== 0) && (typeof props.$index !== 'undefined') ? "380px" :
    props.$text ? "289px" : null,
    width: "269px",
    [media.min.tablet]: {
        height: props.$index % 2 === 0 ? "438px" : "508px",
        width: "360px"
    }
}));

const Image  = styled("div", props => ({
    position: "relative",
    display: "block",
    height: 
    props.$index % 2 === 0 ? "316px" : 
    (props.$index % 2 !== 0) && (typeof props.$index !== 'undefined') ? "380px" :
    props.$text ? "289px" : null,
    width: "269px",
    [media.min.tablet]: {
        height: props.$index % 2 === 0 ? "438px" : "508px",
        width: "360px"
    }
}));

const ContentWrapper = styled("div", {
});


const TextWrapper = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "90px",
    marginBottom: "1rem"
});

const Title = styled("p", {
    fontSize: "1.875rem",
    lineHeight: "2.125rem"
});

const Description = styled("p", {
    fontSize: "1rem",
    lineHeight: "1.25rem",
    marginTop: "2px"
});


const renderWrapper = (blok, index) => {

    const link = blok.link.url.trim() !== "" ? blok.link.url : blok.link.cached_url;
    if (link == "") {
        if (blok.description.trim() !== "") {
            return (
                <ContentWrapper>
                    <TextWrapper>
                        <Title>{blok.title}</Title>
                        <Description>Av {blok.description}</Description>
                    </TextWrapper>
                    <Image $text={true}>

                        <BlokImage
                            wrapperStyle={{
                                height: "100%",
                                width: "100%",
                                position: "relative",
                                filter: "brightness(0.77)",
                                top: "0",
                                left: "0",
                            }}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt={blok.title}
                            fluid={{}}
                            blok={blok.image.filename}
                        />
                    </Image>
                </ContentWrapper>
            )
        } else {
            return (
                <Image $index={index}>
                    <BlokImage
                        wrapperStyle={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                            filter: "brightness(0.77)",
                            top: "0",
                            left: "0",
                        }}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={blok.title}
                        fluid={{}}
                        blok={blok.image.filename}
                    />
                </Image>
            )
        }
    } else {
        if (blok.description.trim() !== "") {
            return (
                <ContentWrapper>
                    <TextWrapper>
                        <Title>{blok.title}</Title>
                        <Description>Av {blok.description}</Description>
                    </TextWrapper>
                    <ImageLink $text={true} href={link}>

                        <BlokImage
                            wrapperStyle={{
                                height: "100%",
                                width: "100%",
                                position: "relative",
                                filter: "brightness(0.77)",
                                top: "0",
                                left: "0",
                            }}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt={blok.title}
                            fluid={{}}
                            blok={blok.image.filename}
                        />
                    </ImageLink>
                </ContentWrapper>
            )
        } else {
            return (
                <ImageLink $index={index} href={link}>
                    <BlokImage
                        wrapperStyle={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                            filter: "brightness(0.77)",
                            top: "0",
                            left: "0",
                        }}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt={blok.title}
                        fluid={{}}
                        blok={blok.image.filename}
                    />
                </ImageLink>
            )
        }
    }
}

const Slide = ({ blok, index, length }) => (
    <Container $length={length} $index={index}>
        { renderWrapper(blok, index) }
    </Container>
);

export default Slide;