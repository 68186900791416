import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media, colors } from "config/theme";
import Column from "components/layouts/Column";
import FAQItem from "./FAQItem";
import DefaultWrapper from "components/layouts/DefaultWrapper";


const Title = styled("h2", props => ({
  fontSize: "1.875rem",
  marginBottom: "2.5rem",
  marginTop: "1rem",
  
  [media.min.tablet]: {
    marginLeft: props.$layout === 'horizontal' ? '0' : '0',
    width: props.$layout === 'horizontal' ? '33%' : 'auto',
    fontSize: "1.875rem"
  },

  [media.min.laptop]: {
    fontSize: "3rem",
    lineHeight: "3rem",
  }
 
}));

const Items = styled("div", props => ({
  borderTop: "1px solid " + colors.black,
  [media.min.tablet]: {
    width: props.$layout === 'horizontal' ? '66%' : 'auto',
    marginRight: props.$layout === 'horizontal' ? '2rem' : '0',
    marginTop: "1rem"
  }
}));

const Text = styled("p", props => ({
  margin: "1rem 0 1rem 0",

  [media.min.tablet]: {
    margin: "1rem 0 0rem 0"
  }
}));

const List = ({ blok }) => {
  const layout = blok.layout;

  return (
    <SbEditable content={blok}>
      <DefaultWrapper layout={blok.layout} columns={true}>
        <Column space="4" layout={blok.layout}>
          <Title>{blok.title}</Title>
        </Column>
        <Column layout={blok.layout}>
          {blok.text.trim() !== "" ? 
            <Text>{blok.text}</Text> 
          : null}
          <Items>
            {blok.items.map((blok, index) => (
                React.createElement(FAQItem, {
                  key: blok._uid,
                  blok: blok,
                  layout: layout
                })
              )
            )}
          </Items>
        </Column>
      </DefaultWrapper>
    </SbEditable>
    );
};

export default List;