import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { colors, media } from "../../config/theme";
import BlokImage from "components/BlokImage";

const getColor = props => {
  let color = `'${colors.white}'`;
  if (props.theme === 'dark') color = `${colors.white}`;
  if (props.theme === 'light') color = `${colors.black}`;
  return color;
};

const getBackground = props => {
  let color = `'${colors.white}'`;
  if (props.theme === 'light') color = `${colors.white}`;
  if (props.theme === 'dark') color = `${colors.black}`;
  return color;
};


const HeroWrapper = styled("div", props => ({
  color: `${getColor(props)}`,
  backgroundColor: `${getBackground(props)}`,
  position: "relative",
  height: "50vh",
  // marginBottom: "3rem",
  marginBottom: "0.75rem",


  [media.min.tablet]: {
    height: "60vh",
    padding: "0 24px 24px 24px",
    marginBottom: "4rem",
  },

  [media.min.laptop]: {
    height: "600px",
    padding: "0 32px 32px 32px",
    marginBottom: "10px"
  },

  [media.min.desktop]: {
    padding: "0 72px 72px 72px",
  },

  [media.min.desktop]: {
    marginBottom: "1rem"
  }
}));

const Title = styled("div", props => ({
  position: "absolute",
  top: "1rem",
  left: "1rem",
  fontSize: "3.125rem",
  lineHeight: "1",

  [media.min.tablet]: {
    lineHeight: "1.02",
    top: "2rem",
    left: "2rem",
  },

  [media.min.laptop]:  {
    width: "80%",
    fontSize: "6.5rem",
  },

  [media.min.maxWidth]:  {
    width: "80%",
    fontSize: "6.5rem",
    left: "3rem"
  },
}));


const HeroBlock = ({ blok }) => (
  <SbEditable content={blok}>
    <HeroWrapper  {...blok}>
      {blok.background_image &&
        <BlokImage
          wrapperStyle={{
            height: "100%",
            width: "100%",
            position: "absolute",
            filter: "brightness(0.77)",
            top: "0",
            left: "0",
          }}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={blok.title}
          fluid={{}}
          blok={blok.background_image}
        />
      }
      <Title>
        {blok.title}
      </Title>
    </HeroWrapper>

  </SbEditable>
);

export default HeroBlock;