import React from "react";
import GalleryItem from './GalleryItem';
import { styled } from 'libs/styletron';
import { media } from "config/theme";


const Wrapper = styled("div", { 
    margin: "0 auto",
    marginBottom: "2.375rem",
    
    [media.min.tablet]:{
        marginBottom: "9rem",
        height: "86vw",
    }
});

const GalleryWrapper = styled("div", {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "0 1rem",

    [media.min.tablet]: {
        display: "grid",
        gridTemplateColumns: "repeat(40, 2.5%)",
        gridTemplateRows: "repeat(18, 4%)",
        margin: "0 1rem 0 1rem"
    },

    [media.min.laptop]: {
        margin: "0 2rem"
    },

    [media.min.maxWidth]: {
        margin: "0 4rem"
    }
});

const Gallery = ({ blok }) => {
    return (
        <Wrapper>
            <GalleryWrapper>
                    {blok.items.map((blok, index) =>
                        React.createElement(GalleryItem, {
                            key: blok._uid,
                            blok: blok,
                            index: index
                        })
                    )}
            </GalleryWrapper>
        </Wrapper>
    )
} 

export default Gallery;