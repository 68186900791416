import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";

const Wrapper = styled("div",  props => ({ 
    padding: props.$mobileSlider ? "1rem 0 5rem 1rem" : "1rem 1rem 5rem 1rem",
    borderTop: props.$border === 'none' ? "none" : props.$border === "light"  ? "1px solid " + colors.grey.light : "1px solid " + colors.grey.dark,

    [media.min.laptop]: {
        padding: (props.$layout ===  'horizontal' && props.$columns) || props.$layout ===  'grid' ?  "1rem 1rem, 5rem 2rem" :  "1rem 2rem 5rem 2rem",
    },

    [media.min.desktop]: {
        /*paddingLeft: props.$fullWidth ? "2rem" : "calc((100vw - 850px)/6 + 15px)",
        paddingRight: props.$fullWidth ?  "2rem" : "calc((100vw - 850px)/6 + 15px)"
        */
    },
}));

const Inner = styled("div", props => ({ 
    [media.min.tablet]: {
        display: props.$layout === 'horizontal' || props.$layout === 'grid' ? 'flex' : 'block',
        flexWrap: props.$layout === 'grid' ? "wrap" : "unset",
    },

    [media.min.desktop]: {
        margin: (props.$layout ===  'horizontal' && props.$columns) || props.$layout ===  'grid' ? "0 -1rem" : "0"
    }
}));

const DefaultWrapper = ({ layout, border, mobileSlider, columns, fullWidth, children }) => (
    <Wrapper $mobileSlider={mobileSlider} $border={border} $layout={layout} $fullWidth={fullWidth}>
        <Inner  $layout={layout} $columns={columns}>
            {children}
        </Inner>
    </Wrapper>
);

export default DefaultWrapper;