import React from "react";
import { styled } from 'libs/styletron';
import SbEditable from "storyblok-react";


const Link = ({ to, children, ...rest }) => (
  to ? <a href={to} {...rest}>{children}</a> : children
);


const Arrow = styled('svg', {
  marginLeft: "0.5rem",
});


const LinkItem = ({ blok, showComma, arrow, index }) => (

  <SbEditable content={blok}>
    <Link to={blok.url.url} target="_blank">
        {blok.text}
        {arrow && 
          <Arrow width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 10V0.999999H0.000273854" stroke="black"/>
              <line y1="-0.5" x2="12.0335" y2="-0.5" transform="matrix(-0.712994 0.70117 -0.701148 -0.713016 8.57983 1)" stroke="black"/>
          </Arrow>
        }
    </Link>
    {showComma ? ', ' : ''}
  </SbEditable>

);




export default LinkItem;