import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import TextColumn from "./TextColumn";
import DefaultWrapper from "components/layouts/DefaultWrapper";

const Title = styled("h2", {
  fontSize: "1.5625rem",
  marginBottom: "3rem",

  [media.min.tablet]: {
    fontSize: "2rem",
    marginBottom: "5rem",
  },

  [media.min.laptop]: {
    fontSize: "1.875rem",
    marginTop: "0.5rem"
  },
  
  [media.min.maxWidth]: {
    marginTop: "1rem"
  }
});

const Columns = styled("div", {
  [media.min.tablet]: {
    display: "flex",
    marginLeft: "-1rem",
    marginRight: "-1rem"
  },
});


const TextColumns = ({ blok }) => (
  <SbEditable content={blok}>
    <DefaultWrapper>
        <Title>{blok.title}</Title>
        <Columns>
            {blok.columns.map(blok =>
                React.createElement(TextColumn, {
                    key: blok._uid,
                    blok: blok,
                })
            )}
        </Columns>
    </DefaultWrapper>
  </SbEditable>
);

export default TextColumns;