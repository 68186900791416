/**
 * @param {any} x - If x is not an array, wrap it in one
 * @returns {array}
 */

 export const asArray = (x: any): any[] => (x !== undefined && x !== null ? (Array.isArray(x) ? x : [x]) : []);

 /**
  * Creates an array of unique values that are included in all given arrays
  *
  * @param {...Array} [arrays] - The arrays to inspect.
  * @returns {Array} - Returns the new array of intersecting values.
  * @example
  *      intersection([1, 2], [1, 2], [2, 3]) // => [2]
  */
 export const intersection = (...arrays: (number | string)[][]) =>
     arrays.length ? arrays.reduce((p, c) => p.filter(e => c.includes(e))) : [];
 