import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import SbEditable from 'storyblok-react';

const Item = styled("div", props => ({
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifySelf: "center",
    justifyContent: "center",

    [media.min.tablet]: {
        justifyContent:
        props.$index === 0 ? "flex-start" :
        props.$index === 4 ? "flex-start" :
        "center",
    },
    
    [media.min.laptop]: {
        width: "100%",
    }
}));


const Image  = styled("img", props => ({
    width:"auto",
}));

const Speaker = ({blok, index}) => {
    return (
        <SbEditable content={blok}>
            <Item $index={index}>
                <Image src={blok.image} />
            </Item>
        </SbEditable>
    )
}   

export default Speaker;