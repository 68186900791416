import React, {useEffect} from "react";
import Components from "components/components.js";
import setTheme from 'helpers/setTheme';
import MetaData from 'components/Metadata';

const Page = props => {
  const {blok, name, full_slug} = props;
  const {metadata} = blok;
  

  useEffect(() => {
    setTheme(blok.Theme);
    window.scrollTo(0,0);

  }, [blok.theme]);

  let seoMeta, seoImage, overrideTitle;

  if (metadata && metadata.title) {
    overrideTitle = metadata.title;
  }


  return (
    <React.Fragment>
      <MetaData
        type="website"
        seoTitle={name}
        url={full_slug}
        overrideTitle={overrideTitle}
        seoMetaDescription={seoMeta}
        seoOgimage={seoImage}
      />
      {blok.hero &&
        blok.hero.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok
          })
      )}


      {blok.body &&
        blok.body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok
        })
      )}
    </React.Fragment>
  );
};

export default Page;