import React from "react";
import SbEditable from "storyblok-react";
import { styled } from "styletron-react"
import { colors, media } from "config/theme";
import ScheduleItem from "./ScheduleItem";
import DefaultWrapper from "components/layouts/DefaultWrapper";


const Title = styled("h2", {
  fontSize: "1.875rem",
  lineHeight: "2.125rem",
  padding: "0 0rem 5rem 0rem",
  
  [media.min.tablet]: {
    padding: "0 0.75rem 7.75rem 0.75rem",
  },

  [media.min.laptop]: {
    padding: "0 0.1rem 7.75rem 1rem",
  },
});

const Items = styled("div", {
  borderTop: "1px solid " + colors.black,
  margin: "0",
  [media.min.tablet]: {
    margin: "0 0.75rem",
  }
});

const Schedule = ({ blok, fullWidth }) => (
  <SbEditable content={blok}>
    <DefaultWrapper border="light" fullWidth={fullWidth}>
      <Title>{blok.title}</Title>
        <Items>
          {blok.items.map((blok, index) => (
              React.createElement(ScheduleItem, {
                key: blok._uid,
                blok: blok,
                index
              })
            )
          )}
        </Items>
    </DefaultWrapper>
  </SbEditable>
);

export default Schedule;