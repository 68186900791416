import React from "react";
import Speaker from './Speaker';
import DefaultWrapper from "components/layouts/DefaultWrapper";


const Speakers = ({ speakers }) => speakers ? (
    <DefaultWrapper layout="grid" border="none">
        {speakers.map(blok =>
            React.createElement(Speaker, {
                key: blok._uid,
                blok: blok,
            })
        )}
    </DefaultWrapper>
) : null;

export default Speakers;