import React from "react";
import Drawer from "components/ui/Drawer";
import Form from "components/Form";
import { allowScroll, preventScroll } from 'helpers/scroll';

class DefaultForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
        };

        this.toggleForm = this.toggleForm.bind(this);
    }


    toggleForm() {
        const showForm = this.state.showForm;

        if (!showForm) {
            preventScroll();
        } else {
            allowScroll();
        }

        this.setState({
            showForm: !showForm,
        });
    }

    render () {
        const { title, speaker } = this.props;
        const { showForm } = this.state;

        return (
            <Drawer 
                open={showForm}
                onClick={this.toggleForm} 
             >
                <Form
                    title={title}
                    speaker={speaker}
                />
            </Drawer>
        )
    };
};

export default DefaultForm;