import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import BlokImage from "components/BlokImage";
import SbEditable from 'storyblok-react';
import Button from 'components/buttons/Button';
import Fade from 'react-reveal/Fade';
import AspectWrapper from "components/AspectWrapper";

const Item = styled("div", props => ({
    display: "flex",
    position: 'relative',
    paddingBottom: "1.25rem",
    marginBottom: "1.25rem",
    alignItems: "flex-start",
    borderBottom: `1px solid ${colors.black}`,

    [media.min.tablet]: {

    },
}));

const Request = styled("span", {  
    display: "none",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    textTransform: "uppercase",
    width: "20%",
    flexDirection: "column",
    alignItems: "flex-start",

    [media.min.tablet]: {
        display: "flex",

    },

    [media.min.laptop]: {
        fontSize: "1rem",
    },
})

const FirstDate = styled("div", {  
    display: "none",
    textAlign: "center",
    textTransform: "uppercase",
    padding: "0 0rem 0 0.5rem",

    [media.min.tablet]: {
        display: "flex",

    },

    width: "20%",
    flexDirection: "column",
    alignItems: "flex-start"
})

const Big = styled("span", {  
    display: "block",
    fontSize: "3.125rem",

    [media.min.laptop]: {
        fontSize: "6.375rem",
        lineHeight: "6.375rem",
    },
})

const Small = styled("span", {  
    display: "block",
    fontSize: "0.8125rem",
    [media.min.laptop]: {
        fontSize: "1rem",
        marginLeft: "0.7rem",
    },
})

const Info = styled("div", { 
    [media.min.tablet]: {
        position: "relative",
        marginLeft: "3%",
        width: "100%"
    },

    [media.min.laptop]: {
        marginLeft: "20%",
        width: "100%",
        position: "relative",
        marginLeft: "16.5%",
    },

    [media.min.desktop]: {
        marginLeft: "25%",
        width: "100%",
        position: "relative",
    },
});

const Text = styled("div", { 
    marginTop: "1rem",

    [media.min.tablet]: {
        marginTop: "0rem"
    },

    [media.min.laptop]: {
        marginTop: 0,
        maxWidth: "400px",
        justifyContent: "flex-start",
    },
});

const Image = styled("div", {
    display: "none",
    [media.min.tablet]: {
        display: "block",
        width: "234px",
        height: "100%",
        position: "absolute",
        right: "0",
        top: "0",
    }
});

const ImageMobile = styled("div", {
    display: "block",
    [media.min.tablet]: {
        display: "none"
    }
});

const Img = styled("img", {
    width: "100%",
    maxHeight: "210px",
    [media.min.mobile]:{
        maxHeight: "400px",
        width: "calc(100vw - 32px)"
    },
    objectFit: "cover"
});

const Tag = styled("div", { 
    fontSize: "1rem",
    marginBottom: "0.25rem",
    letterSpacing: "0.03em"
});

const Type = styled("span", { 
    textTransform: "uppercase",
    paddingRight: "10px",
});

const Topic = styled("span", { 
    paddingLeft: "10px",
    textTransform: "capitalize"
});

const Title = styled("div", {
    fontSize: "1.875rem",
    lineHeight: "2.125rem",
    marginBottom: "2px",

    [media.min.tablet]: {
        maxWidth: "385px",
    },

    [media.min.laptop]: {
        maxWidth: "none",
    }
});

const Speaker = styled("div", { 
    fontSize: "2rem",
    lineHeight: "2.625rem",
    marginBottom: "0.5rem",
    color: `${colors.grey.dark}`,
});

const Details = styled("div", { 
    display: "none",

    [media.min.tablet]: {
        fontSize: "1.125rem",
        lineHeight: "1.375rem",
        marginBottom: "3rem",
        color: `${colors.grey.dark}`,
        display: "block"
    }
});

const Actions = styled("div", { 

    [media.min.tablet]: {
    }
});


const Arrow = styled('svg', {
    marginLeft: "0.5rem",
  });

const ListItem = ({ blok }) => {
    const course = blok.course;
    let dates = course.content && course.content.occacions && course.content.occacions.length > 0 && course.content. occacions[0].date;
    let date = undefined;
    const occasions = blok.course.content ? blok.course.content.occacions : [];
    let locations = [];

    const monthNames = ["jan", "feb", "mar", "apr", "maj", "jun",
            "jul", "aug", "sep", "okt", "nov", "dec"
            ];

    if (dates) {
        date = new Date(dates);
    }

    occasions !== "undefined" && occasions?.map((occasions, i) => {
        if (locations.includes(occasions.location) === false) locations.push(occasions.location);
    });

    return (
        <SbEditable content={blok}>
            <Fade>
                <Item> 
                    {course && course.content &&
                        <React.Fragment>
                            {date &&
                                <FirstDate>
                                    <Big>{date && date.getDay()}</Big>
                                    <Small>{date && monthNames[date.getMonth()]}</Small>
                                </FirstDate>
                            }
                            {!date && 
                                <Request>
                                    Intresseanmälan
                                </Request>
                            }
                            <Info>
                                <Image>
                                    <a href={course.full_slug}>
                                            <BlokImage
                                                wrapperStyle={{
                                                    height: "100%",
                                                    width: "100%",
                                                    position: "relative",
                                                    filter: "brightness(0.77)",
                                                    top: "0",
                                                    left: "0",
                                                }}
                                                objectFit="cover"
                                                objectPosition="50% 50%"
                                                alt={blok.title}
                                                fluid={{}}
                                                blok={course.content && course.content.hero[0].image}
                                            />
                                    </a>
                                </Image>
                                <ImageMobile>
                                    <a href={course.full_slug}>
                                        <Img src={course.content.hero[0].image}></Img>
                                    </a>
                                </ImageMobile>
                                <Text>
                                    <a href={course.full_slug}>
                                        <Tag>
                                            <Type>{course.type === 'workshop' ? 'Workshop' : course.type === 'lecture' ? 'Föreläsning' : 'Kurs'}</Type>|
                                            <Topic>{course.content.topic}</Topic>
                                        </Tag>
                                        <Title>{course.name}</Title>
                                        <Speaker>
                                        </Speaker>
                                        <Details>
                                            {locations.map((location, i) => 
                                                <span>{location}{i + 1 !== locations.length ? ", " : ""}</span>
                                            )}
                                            <br />
                                            {course.content.cost } SEK exkl. moms
                                        </Details>
                                        <Actions>
                                            <Button as="a" color="#000">Läs mer 
                                                <Arrow width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 10V0.999999H0.000273854" stroke="black"/>
                                                    <line y1="-0.5" x2="12.0335" y2="-0.5" transform="matrix(-0.712994 0.70117 -0.701148 -0.713016 8.57983 1)" stroke="black"/>
                                                </Arrow>
                                            </Button>
                                        </Actions>
                                    </a>
                                </Text>
                            </Info>
                        </React.Fragment>
                    }
                </Item>
            </Fade>
        </SbEditable>
    )
}   

export default ListItem;