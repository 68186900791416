import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";

const List = styled("ul", {  

});

const Item = styled("li", {

});

const ListItem = ({ blok }) => {
  return (
    <List>
        {/* LOOP list of items */}
        <Item></Item>
    </List>
  )
};

export default ListItem;