import React from "react";
import CourseItem from './ListItem';
import { styled } from 'libs/styletron';
import { colors, media } from "../../config/theme";
import Link from "../../components/buttons/Link"
import DefaultWrapper from "components/layouts/DefaultWrapper";

const Wrapper = styled("div", { 
    marginBottom: "6rem",
    marginTop: "8rem",

    [media.min.laptop]: {
        marginTop: "5rem",
        marginBottom: "7.5rem",
    },
})

const Title = styled("h2", { 
    fontSize: "1.875rem",
    paddingBottom: "2.5rem",

    [media.min.tablet]: {
        paddingBottom: "3rem",
        borderBottom: `1px solid ${colors.black}`,
        marginBottom: '1rem',
    },
    [media.min.laptop]: {
        fontSize: "3rem",
    },
    [media.min.maxWidth]: {
        paddingBottom: "5rem"
    }
});

const CourseList = styled("div", { 
    marginBottom: "3.5rem",
    [media.min.maxWidth]: {
        marginBottom: "7rem",
    }
});

const Actions = styled("div", { 
    textAlign: "center"
});

const Courses = ({ blok }) => {

    return (
        <DefaultWrapper border="none">
            <Wrapper>
            <Title>Utvalda kurser</Title>
            <CourseList>
                {blok.course.map(blok =>
                    React.createElement(CourseItem, {
                        key: blok._uid,
                        blok: blok,
                    })
                )}
            </CourseList>
            <Actions>
                <Link theme="light" href="/utbildning">Upptäck alla utbildningar</Link>
            </Actions> 
        </Wrapper>
        </DefaultWrapper>
    )
} 

export default Courses;