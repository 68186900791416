import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "../../config/theme";
import BlokImage from "components/BlokImage";
import SbEditable from 'storyblok-react';



const Item = styled("div", props => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    margin: props.$index === 0 ? '0 1rem 0 1rem' : "0 1rem 0 0",
    alignSelf: props.$index % 2 === 0 ? "auto" : "flex-start",
    [media.min.tablet]: {
        margin: "0",
        alignSelf: "auto",
        gridColumnStart: 
        props.$index === 0 ? "1" : 
        props.$index === 1 ? "12" :
        props.$index === 2 ? "1" :
        props.$index === 3 ? "19" :
        null,
        gridColumnEnd: 
        props.$index === 0 ? "9" : 
        props.$index === 1 ? "27" :
        props.$index === 2 ? "16" :
        props.$index === 3 ? "27" :
        null,
        gridRowStart: 
        props.$index === 0 ? "1" : 
        props.$index === 1 ? "1" :
        props.$index === 2 ? "8" :
        props.$index === 3 ? "8" :
        null,
        gridRowEnd: 
        props.$index === 0 ? "7" : 
        props.$index === 1 ? "6" :
        props.$index === 2 ? "13" :
        props.$index === 3 ? "14" :
        null,
    }
}));

const Title = styled("p", {
    fontSize: "0.9375rem",
    lineHeight: "1.1875rem",
    [media.min.tablet]: {
        fontSize: "1.125rem",
        lineHeight: "1.375rem",
        color: colors.grey.dark
    }
});

const Name = styled("p", {
    fontSize: "0.9375rem",
    lineHeight: "1.1875rem",
    [media.min.tablet]: {
        fontSize: "1.125rem",
        lineHeight: "1.375rem",
    }
});

const TextWrapper = styled("div", {
    maxWidth: "fit-content",
    marginTop: "1rem"
});

const ImageWrapper  = styled("div", props => ({
    position: "relative",
    display: "flex",
    height: props.$index % 2 === 0 ? "243px" : "146px",
    width: "253px",
    [media.min.tablet]: {
        width: "100%",
        height: "100%"
    }
}));



const Speaker = ({ blok, index }) => {
    
    const link = blok.link.url.trim() !== "" ? blok.link.url :  blok.link.cached_url;
    return (
        <SbEditable content={blok}>
            <Item $index={index} $as={link === "" ? "div" : "a"}  href={link !== "" ? blok.link.url : blok.link.cached_url}>
                <ImageWrapper $index={index}>
                    <BlokImage
                        wrapperStyle={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                            filter: "brightness(0.77)",
                            top: "0",
                            left: "0",
                        }}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt=""
                        fluid={{}}
                        blok={blok.media}
                    />
                </ImageWrapper> 
                <TextWrapper>
                    <Name>{blok.name}—</Name>
                    <Title>{blok.title}</Title>
                </TextWrapper>
            </Item>
        </SbEditable>
    )
}   

export default Speaker;