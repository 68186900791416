import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Column from "components/layouts/Column";
import Button from 'components/buttons/Button';
import Address from './Address';
import Form from "components/Form/DefaultForm";
import DefaultWrapper from "components/layouts/DefaultWrapper";


const Title = styled("div", {
    fontSize: "1.5625rem",
    marginBottom: "5rem",
    [media.min.tablet]: {
        fontSize: "2rem",
        marginTop: "0.5rem"
    },

    [media.min.laptop]: {
        fontSize: "1.875rem",
        marginTop: "0.5rem"
    },
    
    [media.min.desktop]: {
        marginTop: "1rem"
    }
});

const Addresses = styled("div", {
    [media.min.tablet]: {
        fontSize: "1.25rem",
        lineHeight: "130%",
        letterSpacing: "0.02em",
        marginTop: "5.75rem"
    },
});

const Text = styled("p", {
    marginBottom: "0.5rem",
    maxWidth: "75%",

    fontSize: "1rem",
    lineHeight: "1.25rem",

    [media.min.tablet]: {
        letterSpacing: "0.02em",
    },
    [media.min.laptop]: {
        marginTop: "0.5rem",
        maxWidth: "240px"
    },
    [media.min.desktop]: {
        marginTop: "1rem"
    }
});

const Email = styled("a", {
    fontSize: "1rem",
    lineHeight: "1.25rem",
    [media.min.tablet]: {
        letterSpacing: "0.02em",
        textDecoration: "underline"
    },
    [media.min.desktop]: {
        fontSize: "1.125rem",
        lineHeight: "1.375rem",
    }
})

const ContactButton = styled(Button, {
    display: "block",
    marginBottom: "2rem",
    fontSize: "1rem",

    [media.min.laptop]: {
        marginTop: "0.8rem"
    },
});


const Contact = ({ blok }) => {
    const formRef = React.createRef();

    function handleClick() {
        formRef.current.toggleForm();
    }

    return(
        <React.Fragment>
            <DefaultWrapper layout="horizontal">
                <Column space="4">
                    <Title>{blok.title}</Title>
                    
                </Column>
                <Column space="8">
            
                    <Text>{blok.text}</Text>
                        <ContactButton color="#000" onClick={handleClick}>
                            Kontakta oss
                        </ContactButton>
                    <Email href={"mailto:" + blok.email}>{blok.email}</Email>
                    <Addresses>
                        {blok.addresses.map((blok, index) =>
                            React.createElement(Address, {
                                key: blok._uid,
                                blok: blok,
                                index: index
                            })
                        )}
                    </Addresses>
                
                </Column>
            </DefaultWrapper>
            <Form
                title="Säg hej!"
                ref={formRef}
            />
        </React.Fragment>
    )
}

export default Contact;