import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import Column from "components/layouts/Column";
import DefaultWrapper from "components/layouts/DefaultWrapper";
import ListItem from "./ListItem";


const Title = styled("h2", {
  fontSize: "1.5625rem",
  lineHeight: "2.125rem",
  padding: "0 0rem 5rem 0rem",
  
  [media.min.tablet]: {
    padding: "0 0rem 7.75rem 0rem",
    fontSize: "1.875rem",
  },
});

const Items = styled("div", props => ({
  borderTop: props.$design === "lines" ? "1px solid " + colors.black : "none",
  margin: "0",

  [media.min.tablet]: {
    margin: props.$design === "horizontal" ? "0 0.75rem" : "0",
  }
}));

const List = ({ blok, fullWidth }) => {
  const {layout, design} = blok;

  return (
    <SbEditable content={blok}>
      <DefaultWrapper border="light" layout={layout} fullWidth={fullWidth}>
        <Column space="4" layout={blok.layout}>
          <Title>{blok.title}</Title>
        </Column>
        <Column layout={blok.layout}>
          <Items $design={design} layout={blok.layout}>
            {blok.items.map((blok, index) => (
                React.createElement(ListItem, {
                  key: blok._uid,
                  blok: blok,
                  layout,
                  design
                })
              )
            )}
          </Items>
        </Column>
      </DefaultWrapper>
    </SbEditable>
    );
};

export default List;