import React from "react";
import SbEditable from "storyblok-react";
import { Link } from "gatsby"
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import BlokImage from "components/BlokImage";
import Button from "components/buttons/Button"
import LinkComp from "components/link";
import Column from "components/layouts/Column";
import DefaultWrapper from "components/layouts/DefaultWrapper";


const HeroWrapper = styled("div", props => ({
  position: "relative",
  display: "flex",
  fontSize: "1.25rem",
  flexDirection: "column-reverse",

  [media.min.tablet]: {
    height: "40vh",
    flexDirection: "row",
  },

  [media.min.laptop]: {
    height: "65vh",
    minHeight: "500px",
  },

  [media.min.desktop]: {
    marginLeft: "-1rem",
    marginRight: "-1rem"
  },

  
}));

const Name = styled("h1", {

  fontSize: "3.125rem",

  [media.min.tablet]: {
  },

  [media.min.desktop]: {
    fontSize: "4.5rem",
    lineHeight: "4.625rem"
  }
});

const Title = styled("div", {
  marginBottom: "0.25rem",
  fontSize: "1rem",
  lineHeight: "1.25rem",

  [media.min.desktop]: {
    fontSize: "1.125rem",
    lineHeight: "1.375rem",
  }
});

const GrowWrapper = styled("div", {
  flexGrow: "1"
})


const Social = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  marginBottom: "1rem",
  fontSize: "1rem",
  lineHeight: "1.25rem",
  
  [media.min.tablet]: {
    marginBottom: "0",
  },

  [media.min.desktop]: {
    fontSize: "1.125rem",
    lineHeight: "1.375rem",
    maxWidth: "40%",
  }
});

const SocialLink = styled("div", {
  marginBottom: "0.25rem",
  width: "fit-content",
  height: "fit-content",
  borderBottom: "1px solid #000",
  marginRight: "11px",
});

const Org = styled("span", {
  display: "inline-block",
});

const Text = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "1.125rem 1rem 0 1rem",

  [media.min.tablet]: {
    height: "100%",
    padding: "0"
  },
 
});

const Actions = styled("div", {
  justifyContent: "flex-end",
});

const ScheduleButton = styled(Button, {
  fontSize: "1rem",
  borderRadius: "6px",
  marginBottom: "12px",

  [media.min.tablet]: {
    marginBottom: "1rem",
  },

  [media.min.desktop]: {
    fontSize: "1.125rem",
  },
});

const Arrow = styled("svg", {
  marginLeft: "10px"
});

const ReadMore = styled(Link, {
    display: "block",
    position: "relative",
    color: colors.black,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: colors.black,
    flex: "1 1 0",
    alignItems: "center",
    padding: "0.7rem 2rem",
    borderRadius: "50px",
    fontSize: "1rem",
    textAlign: "center",

    [media.min.desktop]: {
      fontSize: "1.125rem",
    },

    ":hover": {
      color: colors.white,
      background: colors.black,
      borderColor: colors.black,
    }
    
});

const SpeakerHero = ({ blok, name, onClick }) => (
  <SbEditable content={blok}>
    <DefaultWrapper border="none">
      <HeroWrapper>
        <Column space="4">
          <Text>
            <Name>{name}</Name>
            <Title>{blok.title} på <Org>{blok.organization}</Org></Title>
            <GrowWrapper>
              <Social>
                {blok.social && blok.social.map((link) =>
                  <SocialLink>
                    <LinkComp blok={link} arrow={true} />
                  </SocialLink>
                )}
              </Social>
            </GrowWrapper>
            <Actions>
              <ScheduleButton theme="dark" width="100%" onClick={onClick}  >Boka föreläsare</ScheduleButton>
              <ReadMore className="read-more-btn-secondary" width="100%" to="#education">
                Föreläsningar 
                <Arrow width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.13605 6.86401L7.50001 13.228L13.8638 6.86421" stroke="black"/>
                  <line y1="-0.5" x2="12.0335" y2="-0.5" transform="matrix(0.00836097 -0.999965 0.999965 0.00839148 7.79714 12.9309)" stroke="black"/>
                </Arrow>
              </ReadMore>
            </Actions>
          </Text>
        </Column>
        <Column>
          <BlokImage
            wrapperStyle={{
              height: "100%",
              width: "100%",
              position: "relative",
              filter: "brightness(0.77)",
              top: "0",
              left: "0",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={name}
            fluid={{}}
            blok={blok.image.filename}
          />
        </Column>
      </HeroWrapper>
    </DefaultWrapper>
  </SbEditable>
);

export default SpeakerHero;