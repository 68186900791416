import React, { Fragment, PureComponent } from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import Components from "components/components";
import { allowScroll, preventScroll } from 'helpers/scroll';
import Drawer from "components/ui/Drawer";
//import Book from "../components/Form/book";
import Form from "components/Form";
import setTheme from 'helpers/setTheme';
import Button from 'components/buttons/Button';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SpeakersRelated from "modules/SpeakersRelated";
import Courses from 'modules/ProductGrid';
import DefaultWrapper from "components/layouts/DefaultWrapper";
import RadioButton from 'components/RadioButton';
import MetaData from 'components/Metadata';


const ProductWrapper = styled("div", props => ({


  [media.min.tablet]: {
  },

  [media.min.laptop]: {

  },
}));

const Info = styled("div", props => ({
  
  [media.min.tablet]: {
    
  },

  [media.min.laptop]: {
    position: "relative",
    display: "flex",
    // borderBottom: "1px solid " + colors.grey.light
  },
}));

const Content = styled("div", props => ({

  [media.min.laptop]: {
    width: "60%",
  },
}));


const Sidebar = styled("div", props => ({
  display: "none",

  [media.min.laptop]: {
    display: "block",
    position: "sticky",
    top: "0",
    padding: "4.1rem 2rem 2rem 2rem",
    width: "40%",
    right: "0",
    height: "100vh",
    borderLeft: "1px solid #C6C6C6"
  },
}));



const Body = styled("div", props => ({
  margin: " 0",
  paddingTop: "19px",

  [media.min.tablet]: {
    
  },

  [media.min.laptop]: {
    margin: "",
  },
}));

const IngressWrapper = styled("div", {
  borderTop: "0.8px solid #C6C6C6",
  [media.min.laptop]: {
    borderTop: "none"
  }
});

const Ingress = styled("div", props => ({
  "h2": "color: #ff00ee",
  margin: "1rem 1rem 5rem 1rem",

  
  [media.min.tablet]: {
    margin: "1rem 2rem 4rem 2rem",

  },

  [media.min.laptop]: {
    fontSize: "1.125rem",
    lineHeight: "30px",
    margin: "0rem 2rem 4rem 2rem",
  },

  [media.min.maxWidth]: {
    margin: "0rem 2rem 4rem 4rem",
  }
}));

const P = styled("p", props => ({

  fontSize: "1rem",
  lineHeight: "1.25rem",
  
  [media.min.tablet]: {
  

  },

  [media.min.laptop]: {
    fontSize: "1.125rem",
    lineHeight: "1.375rem"
  },
}));

const H2 = styled("div", props => ({
  fontSize: "1.5625rem",
  lineHeight: "2.125rem", 
  marginBottom: "5rem",
  
  [media.min.tablet]: {
    
  },

  [media.min.laptop]: {
    marginBottom: "100px",
    fontSize: "1.875rem", 
  },
}));

const Actions = styled("div", props => ({

  alignItems: "center",
  
  [media.min.tablet]: {
    
  },

  [media.min.laptop]: {
  
  },
}));

const Questions = styled("div", {
  marginTop: "1rem",
  color: "#737373",
  textAlign: "center",
});

const QuestionsLink = styled("a", {
  color: "#737373",
  textDecoration: "underline",
  fontWeight: "500"
});

const H3 = styled("h3", {
    fontSize: "1rem",
    textTransform: "uppercase",
    marginBottom: "1rem",
    lineHeight: "20px",
    letterSpacing: "3%",

    [media.min.laptop]: {
        marginBottom: "0.5rem",
    }
})

const Occasions = styled("div", {
  marginBottom: "2.5rem"
})

const Occasion = styled("div", props => ({
  position: "relative",
  border: props.$checked ? "1px solid " + colors.black : "1px solid " + colors.grey.light,
  borderRadius: "6px",
  padding: "1rem",
  marginBottom: "1rem",
  cursor: "pointer",

  [media.min.laptop]: {
    borderBottom: props.$last ? "1px solid " + colors.grey.light : "none",
    marginBottom: "0",
    display: "flex",
    borderRight: "none",
    borderLeft: "none",
    padding: "1rem 0",
    borderRadius: "0",
  },
}))


const Radio = styled("div", {
  display: "none",
  [media.min.laptop]: {
    display: "block",
  },
})

const Location = styled("div", {
  
  [media.min.laptop]: {
    marginLeft: "72px",
  },
})

const Desc = styled("span", {
  marginTop: "0.25rem",
  color: "#7E7E7E",
  display: "block"
})

const Price = styled("div", {
  marginLeft: "auto",
  position: "absolute",
  top: "0.75rem",
  right: "0.75rem",
  textAlign: "right",

  [media.min.laptop]: {
    position: "relative",
    top: "unset",
    right: "unset",
  }
})

const PriceDesc = styled("span", {
  color: "#7E7E7E",
});



const Type = styled("div", props => ({
  textTransform: "uppercase",

  [media.min.tablet]: {
    
  },

  [media.min.laptop]: {
  },
}));


const Speakers = styled("div", {
});



const RelatedHeader = styled("h2", {
  fontSize: "1.875rem",
  lineHeight: "2.125rem",
  marginBottom: "6rem",
  marginLeft: "0",
  
  [media.min.tablet]: {
    marginLeft: "1rem",
  }
  
});

const MobileProduct = styled("div", {
  display: "block",
  margin: "0.5rem 1rem 5rem 1rem",

  [media.min.laptop]: {
    display: "none"
  }
});

const H1 = styled("h1", {
  fontSize: "2.25rem",
  margin: "0.5rem 0 2.25rem 0",

  [media.min.tablet]: {
    fontSize: "2.8125rem",
    margin: "0 0 4rem 0"
  }
});

const TopBorder = styled("div", {
  display: "none",
  borderBottom: "1px solid #C6C6C6",
  margin: "0 -2rem 1rem -2rem",
  width: "initial",
  height: "1px",

  [media.min.tablet]: {
    display: "block"
  }
});

function numberWithSpace(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

class Product extends PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      occasion: ""
    };

    this.formRef = React.createRef();
    this.titleRef = React.createRef();
    this.setOccasion = this.setOccasion.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  componentDidMount() {
    setTheme('light', true);
    window.scrollTo(0,0);

    //this.scrollFunc();
  }

  componentWillUnmount(){
  }
  

  toggleForm() {
    const showForm = this.state.showForm;

    if (!showForm) {
      preventScroll();
    } else {
      allowScroll();
    }

    this.setState({
      showForm: !showForm,
    });
  }


  scrollFunc() {
    if (this.titleRef.current == null) return
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    ScrollTrigger.create({
      trigger: this.titleRef.current,
      pin: true,
      start: "top top",
      markers: true,
      pinSpacing: false
    });
  }

  onChangeValue(event) {
    this.setState({
      occasion: event.target.value
    });

    this.formRef.current && this.formRef.current.changeOccasion(event.target.value);
  }

  setOccasion(occacion) {
    this.setState({
      occasion: occacion
    });

    this.formRef.current && this.formRef.current.changeOccasion(occacion);
  }



  render() {
    const { blok, name, full_slug, speakers, related } = this.props;
    
    const { showForm} = this.state;
    const {type} = blok;
    const {metadata} = blok;
    let seoImage, overrideTitle;
    
    if (metadata && metadata.title) {
        overrideTitle = metadata.title;
    }

    if (metadata && metadata.title) {
        overrideTitle = metadata.title;
    }

    blok.hero &&blok.hero.map((blok) => {
      seoImage = blok.image
    });

    let course = [];
    
    if (related) {
      course = related.map((blok, index) => (
        {
            _uid: "key",
            course: {
                name: blok.name,
                full_slug: blok.url,
                tag_list: [
                    ""
                ],
                content: {
                    hero: [
                            {
                                Text: blok.text,
                                image: blok.image
                            }
                    ],
                    type: blok.type,
                    topic: blok.topic,
                    courseLocation: blok.courseLocation,
                    cost: blok.cost,
                    speakers: [],
                    occacions: blok.occacions
                }
            }, 
            component: "course_item"
        }  
    ));
  }

  let courseBlock = {
      course
  };

    return (
      <Fragment>
      <MetaData
          type="website"
          seoTitle={name}
          url={full_slug}
          overrideTitle={overrideTitle}
          seoMetaDescription={blok.ingress}
          seoOgimage={seoImage}
        />
      <ProductWrapper>
          <Info>
            <Content>
              {/* Hero */}
              {blok.hero &&
                blok.hero.map((blok) =>
                  React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                    type,
                    name
                  })
                )}

              <MobileProduct>
                <Type>{blok.type === 'workshop' ? 'Workshop' : blok.type === 'lecture' ? 'Föreläsning' : 'Kurs'}</Type>
                <H1>{name}</H1>
                <H3>Tillfällen</H3>
                <Occasions>
                  {blok.occacions?.map((occasion, i) =>
                    <Occasion 
                      name={"occasion" + i}
                      onClick={() => this.setOccasion(occasion.location + (occasion.date ? " - " + occasion.date.split(' ')[0] : ""))}
                      $checked={this.state.occasion === occasion.location + (occasion.date ? " - " + occasion.date.split(' ')[0] : "")}
                    >
                      <Location>{occasion.location}</Location>
                      <Desc>{occasion.date ? occasion.date.split(' ')[0] : "Datum för tillfället är inte bestämt"}</Desc>
                      <Price>
                        {numberWithSpace(occasion.price)} SEK<br />
                        <PriceDesc>exkl. moms</PriceDesc>
                      </Price>
                    </Occasion>
                    )}
                </Occasions>
                <Actions>
                    <Button shape="square" theme="light" onClick={this.toggleForm} disabled={this.state.occasion === ""} width="100%">
                    {this.state.occasion === "" ? "Välj tillfälle" : "Jag är intresserad"}  
                  </Button>
                  <Questions>
                    Har du frågor? <QuestionsLink href="mailto:academy@grebban.com">Kontakta oss</QuestionsLink>
                  </Questions>
                </Actions>
              </MobileProduct>
              <Body id="about">
                {/* Ingress */}
                <IngressWrapper>
                  <Ingress>
                    <H2>Om {blok.type === 'workshop' ? 'workshopen' : blok.type === 'lecture' ? 'föreläsningen' : 'kursen'}</H2>
                    <P>{blok.ingress}</P>
                  </Ingress>
                </IngressWrapper>

                {/* Body */}
                {blok.body &&
                blok.body.map((blok) =>
                  React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                    course: blok.heading,
                    showForm: this.toggleForm,
                    fullWidth: true
                  })
                )}
              </Body>
              {Object.keys(speakers).length !== 0 &&
                <Speakers>
                    <SpeakersRelated speakers={speakers} />
                </Speakers>
              }
            </Content>
            <Sidebar ref={this.titleRef}>
              <TopBorder></TopBorder>
              <Type>{blok.type === 'workshop' ? 'Workshop' : blok.type === 'lecture' ? 'Föreläsning' : 'Kurs'}</Type>
              <H1>{name}</H1>
              <H3>Tillfällen</H3>
              <Occasions >
                {blok.occacions?.map((occasion, i) =>
                  <Occasion $last={i + 1 === blok.occacions.length}  onClick={() => this.setOccasion(occasion.location + (occasion.date ? " - " + occasion.date.split(' ')[0] : ""))}>
                    <Radio>
                      <RadioButton 
                        name="Occasion"
                        checked={this.state.occasion === occasion.location + (occasion.date ? " - " + occasion.date.split(' ')[0] : "")}
                        text={occasion.location + (occasion.date ? " - " + occasion.date.split(' ')[0] : "")}
                        onChange={() => console.log('radio')}>
                      </RadioButton>
                    </Radio>
                    <Location>
                      {occasion.location}
                      <Desc>{occasion.date ? occasion.date.split(' ')[0] : "Datum för tillfället är inte bestämt"}</Desc>
                    </Location>
                    <Price>
                      {numberWithSpace(occasion.price)} SEK<br />
                      <PriceDesc>exkl. moms</PriceDesc>
                    </Price>
                  </Occasion>
                )}
              </Occasions>  
              <Actions>
                <Button theme="light" onClick={this.toggleForm} disabled={this.state.occasion === ""} width="100%">
                  {this.state.occasion === "" ? "Välj tillfälle" : "Jag är intresserad"}  
                </Button>
                <Questions>
                  Har du frågor? <QuestionsLink href="mailto:academy@grebban.com">Kontakta oss</QuestionsLink>
                </Questions>
              </Actions>
            </Sidebar>
          </Info>
          
        <DefaultWrapper mobileSlider={true} fullWidth={true}>
          <RelatedHeader>Fler kurser</RelatedHeader>
          <Courses short={true} mobileSlider={true} blok={courseBlock} />
        </DefaultWrapper>

        <Drawer 
          open={showForm}
          onClick={this.toggleForm}
          /*layout="full" */
        >
          <Form
              title="Anmälan"
              course={name}
              occasion={this.state.occasion}
              ref={this.formRef}              
            />
          {/* TODO WHEN THERE IS occacions
          <Book
            title={name}
            CourseType={blok.courseType}
            speakers={speakers}
            occacions={blok.occacions}
          />
          */}
        </Drawer>
      </ProductWrapper>
      </Fragment>
    );
  }
};

export default Product;