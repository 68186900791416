import React from "react";
import { styled } from 'libs/styletron';

const Styled = styled('div', {
    padding: "0 0",
    position: "relative"
});


const ModuleWrapper = ({size, color, children }) => {
    return (
        <Styled>
            {children}
        </Styled>
    )
}

export default ModuleWrapper;