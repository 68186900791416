import React from 'react';
import { styled } from 'libs/styletron';
import { colors } from 'config/theme';

const StyledLink = styled('button', props => ({
    // borderRadius: "47px",
    borderRadius: props.$shape === "round" ? "47px" : props.$shape === "square" ? "6px" : "47px",
    color: `${props.disabled ? colors.grey.dark : props.$theme === "clear" ? props.$color : props.$theme === "dark" ? "#fff !important" : "#000"}`,
    padding: "12px 22px 12px 22px",
    lineHeight: "1",
    border: `1px solid`,
    borderColor: `${props.disabled ? colors.grey.dark : props.$color}`,
    background: `${props.$theme === "clear" ? "none" : props.$theme === "dark" ? "#000" : "#fff"}`,
    cursor: "pointer",
    position: "relative",
    letterSpacing: "0.03em",
    width: `${props.$width || "auto"}`,

    transition: "all .2s ease",

    fontSize: "1rem",

    ':hover': {
        borderColor: `${props.disabled ? colors.grey.dark : "rgba(0,0,0,0.0)"}`,
        background: `${props.disabled ? colors.white : "rgba(0,0,0,1.0)"}`,
        color: `${props.disabled ? colors.grey.dark : "#fff"}`,
        stroke: `${props.disabled ? colors.grey.dark : "#fff"}`,
    }
}));

const Arrow = styled('svg', {
    marginLeft: "0.5rem",
});


const Link = ({shape, fill, color, theme, width, arrow, downArrow, children, href, disabled, ...buttonProps }) => {
 
    return (
        <StyledLink href={href} $shape={shape} disabled={disabled} $fill={fill} $color={color} $theme={theme} $width={width} arrow={arrow} {...buttonProps}>
            {children}
            {arrow && 
                <Arrow width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 10V0.999999H0.000273854" stroke="black"/>
                    <line y1="-0.5" x2="12.0335" y2="-0.5" transform="matrix(-0.712994 0.70117 -0.701148 -0.713016 8.57983 1)" stroke="black"/>
                </Arrow>
            }
            {downArrow &&
                <Arrow width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.13605 6.86401L7.50001 13.228L13.8638 6.86421" stroke="black"/>
                    <line y1="-0.5" x2="12.0335" y2="-0.5" transform="matrix(0.00836097 -0.999965 0.999965 0.00839148 7.79714 12.9309)" stroke="black"/>
                </Arrow>
            }
        </StyledLink>
    );
};


export default Link;