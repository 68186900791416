import React, {Fragment, useEffect} from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import setTheme from 'helpers/setTheme';
import SpeakersModule from 'modules/Speakers';
import MetaData from 'components/Metadata';
import DefaultWrapper from "components/layouts/DefaultWrapper";

const Hero = styled("div", props => ({
    marginTop: "4rem",
    padding: "1rem 0",
    display: "flex",
  
    [media.min.tablet]: {
        padding: "1.5rem 0",
    },
  
    [media.min.laptop]: {
        padding: "2rem 0",
        paddingTop: "7.5rem",
    },

    [media.min.desktop]: {
        paddingLeft: "0",
        paddingRight: "0",
    }
}));


const Title = styled("h1", props => ({
    fontSize: "2.5rem",
  
    [media.min.tablet]: {
        fontSize: "3.125rem",
    },

    [media.min.laptop]: {
        //fontSize: "5rem",
        fontSize: "4.8vw"
    },
}));

const Count = styled("sup", props => ({
    fontSize: "1.25rem",
    marginLeft: "0.5rem",
  
    [media.min.tablet]: {
    },
  
    [media.min.laptop]: {
        fontSize: "2rem",
    },
}));


const Speakers = props => {
    const {speakers, name, blok, full_slug} = props;
    const {metadata} = blok;
    let count = speakers.length;
    let seoMeta, seoImage, overrideTitle;
    
    if (metadata && metadata.title) {
        overrideTitle = metadata.title;
    }

    if (metadata && metadata.description) {
        seoMeta = metadata.description;
    }


    useEffect(() => {
        setTheme('light');
        window.scrollTo(0,0);
    }, []);
    
    return (
        <Fragment>
            <MetaData
                type="website"
                seoTitle={name}
                url={full_slug}
                overrideTitle={overrideTitle}
                seoMetaDescription={seoMeta}
                seoOgimage={seoImage}
            />
            <DefaultWrapper border="none" >
                <Hero>
                    <Title>Föreläsare</Title> 
                    <Count>{count}</Count>
                </Hero>
            </DefaultWrapper>

            <SpeakersModule speakers={speakers} />


        </Fragment>
    );
};

export default Speakers;