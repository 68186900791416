import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "../../config/theme";
import Malarquee from 'react-malarquee';

const Row = styled(Malarquee, props => ({
  
  borderBottom: '1px solid #000',

  padding: "1rem 0",
 
  fontSize: "3.125rem",
  whiteSpace: "nowrap",

  [media.min.tablet]: {
    fontSize: "5rem",
    padding: "1.25rem 0"
  },

  [media.min.laptop]: {
    fontSize: "6.5rem",
  },
  
}));

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

const MarqueeRow = ({ blok, index }) => {

  const direction = (index % 2 === 0) ? "left" : "right";
  const speed = getRandomArbitrary(35, 100);

  return (
    <SbEditable content={blok}>
        <Row key={index} direction={direction} $index={index} rate={speed}>
          {blok.items.map((blok, index) => (
            <span key={index}>
              <a href="/kurser/">
                <span>{blok.text}</span>
              </a>
              
              { ' — ' }
              
            </span>
          ))}
        </Row>
    </SbEditable>
  )
};

export default MarqueeRow;