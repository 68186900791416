import React from "react";
import Fade from 'react-reveal/Fade';
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";


const TextWrap = styled("div", props => ({
  padding: "0",
  fontSize: "24px",
  lineHeight: "30px",

  [media.min.ipad]: {
    padding: "0",
    lineHeight: "36px",
  },

  [media.min.laptop]: {
    padding: "32px",
    lineHeight: "36px",
    fontSize: "16px"
  },

  [media.min.desktop]: {
    fontSize: "calc(24px + (46 - 24) * ((100vw - 768px) / (2560 - 768)))",
    lineHeight: "1.15em"
  },

}));



const Text = ({ blok }) => (
  <SbEditable content={blok}>
    <Fade>
      <TextWrap className={blok.fontsize}>
        {blok.text}
      </TextWrap>
    </Fade>
  </SbEditable>
);

export default Text;