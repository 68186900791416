import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Column from "components/layouts/Column";
import ReactMarkdown from 'react-markdown'

const Title = styled("h3", {
  fontSize: "1.75rem",
  paddingBottom: "0.5rem",
  marginBottom: "1.5rem",
  borderBottom: "1px solid #000",

  [media.min.tablet]: {
    fontSize: "1.75rem",
  },

  [media.min.laptop]: {
  },
});

const Text = styled("p", props => ({
  fontSize: "1rem",
  lineHeight: "1.875rem",
  marginBottom: "2rem",

  [media.min.tablet]: {
  },
  
  [media.min.laptop]: {
    fontSize: "1.125rem",
  },
}));

const Arrow = styled('svg', {
  marginRight: "0.5rem",
});



const TextColumns = ({ blok }) => (
  <Column space="4">
    <Title>
      <Arrow width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.89941 20.7993L19.7989 10.8998L9.89941 1.00033" stroke="black" strokeWidth="1.5"/>
        <line x1="19.3306" y1="11.1875" x2="0.612285" y2="11.0307" stroke="black" strokeWidth="1.5"/>
      </Arrow> 
      {blok.title}
    </Title>
    <Text className="text-column">
      <ReactMarkdown>
            {blok.text}
      </ReactMarkdown> 
    </Text>
  </Column>

);

export default TextColumns;