
const setTheme = (color, header) => {
  if (header) {
    document.body.classList.add('headerbg');
  } else {
    document.body.classList.remove('headerbg');
  }

  if (color === 'light') {

    document.body.classList.remove('dark');
    document.body.classList.add('light');

  } else if (color === 'dark') {
    document.body.classList.remove('light');
    document.body.classList.add('dark');

  } else {
    return;
  }
};

export default setTheme;