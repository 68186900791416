import React from "react";
import { styled } from 'libs/styletron';
import { colors } from "../../config/theme";
import SbEditable from 'storyblok-react';

const Item = styled("li", props => ({
    display: "block",
    width: "100%",
    height: "fit-content",
    fontSize: "1.125rem",
    lineHeight: "1.125rem",
    borderBottom: "0.6px solid " + colors.black,
    padding: "0.75rem 0"
}));

const Speaker = ({blok}) => {

    return (
        <SbEditable content={blok}>
            <Item>{blok.text}</Item>
        </SbEditable>
    )
}   

export default Speaker;