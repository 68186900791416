import React from "react";
import { styled } from 'libs/styletron';
import { colors, media } from "config/theme";
import BlokImage from "components/BlokImage";
import Button from 'components/buttons/Button';
import Fade from 'react-reveal/Fade';


function truncate( str, n, useWordBoundary ) {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary 
      ? subString.substr(0, subString.lastIndexOf(" ")) 
      : subString) + "...";
};

const Item = styled("div", {
    width: "calc(100%)",
    marginBottom: "4.5rem",
    position: "relative",
    minHeight: "30rem",

    [media.min.tablet]: {
        marginBottom: "0",
        width: "calc(33.3333% - 1.5rem)",
        padding: "0px 0.75rem 3.5rem",
    },
  
    [media.min.laptop]: {
        width: "calc(25% - 1.5rem)",
        padding: "0px 0.75rem 3.5rem",
    },

    [media.min.desktop]: {
        width: "calc(25% - 2rem)",
        padding: "0px 1rem 3.5rem",
    },
});

const Image = styled("div", {
    height: "336px",
    width: "100%",
    position: "relative",
    marginBottom: "1.125rem",
    overflow: "hidden"
});

const Org = styled("span", {
    display: "inline-block",
    borderBottom: "1px solid " + colors.black
});

const Info = styled("div", {
   marginBottom: "1rem"
});

const Text = styled("p", {
    marginBottom: "1rem",
});

const Name = styled("h2", props => ({
   fontSize: "2rem",
   marginBottom: "0.5rem",

   [media.min.tablet]: {
    },

    [media.min.laptop]: {
        fontSize: "2rem",
        marginBottom: "0.5rem"
    },
}));

const Title =  styled("div", props => ({
    fontSize: "1rem",

   [media.min.tablet]: {
    },

    [media.min.laptop]: {
        fontSize: "1.25rem",
    },
 }));


const Speaker = ({ blok }) => {    

    return (
        <Item>
             <a href={blok.url}>
            <Fade>
               
                    <Image>
                        {blok.image &&
                            <BlokImage
                                wrapperStyle={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute", 
                                }}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                alt={blok.title}
                                fluid={{}}
                                blok={blok.image && blok.image.filename}
                            
                            />
                        }
                    </Image>
                    <Info>
                        <Name>{blok.name}</Name>
                        <Title>{blok.title} at <Org>{blok.organization}</Org></Title>
                    </Info>
                    <Text>{truncate(blok.text, 200, true)}</Text>
                    <Button color="#000" arrow="true">Läs  Mer</Button>
               
            </Fade>
            </a>
        </Item>
    )
}   

export default Speaker;