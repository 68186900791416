import React from "react";
import { styled } from 'libs/styletron';


const Styled = styled("div", {
    width: "16rem",
    maxWidth: "16rem",
    flex: "0 0 16rem",
    marginRight: "8rem",
});

const Counter = styled("div", {
    fontSize: "5rem",
    marginBottom: "1rem"
});

const Title = styled("div", {
    fontSize: "2rem",
    marginBottom: "0.5rem"
});

const Text = styled("p", {
    paddingBottom: "2rem"
});

const Step = ({ blok, index }) => (
    <Styled>
        <Counter>0{index+1}.</Counter>
        <Title>{blok.Title}</Title>
        <Text>{blok.Text}</Text>
    </Styled>
);

export default Step;