import React, {useEffect, useState } from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";


const SlideBarWrapper = styled("div", props => ({
    width: "calc(100% - 2rem)",
    display: "flex",
    position: "absolute",
    left: "1rem",

    [media.min.tablet]: {
        display: props.$mobileSlider ? "none" : "flex",
        width: "calc(100% - 4rem)",
        left: "2rem",
    },

    [media.min.maxWidth]: {
        width: "calc(100% - 8rem)",
        left: "4rem",
    }
}));

const SlideLine = styled("div", {
    width: "100%",
    border: "1px solid #DBDBDB"
});

const SlideBarIndicator = styled("div", props => ({
    position: "absolute",
    width: "24.7813411%",
    border: "1px solid #000000",
    bottom: "0",
    left: props.$position
}));

const SliderBar = ({ slideRef, mobileSlider }) => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        function handleScroll(){
            let share = (((slideRef.current.scrollLeft / (slideRef.current.scrollWidth - slideRef.current.offsetWidth)) * 100));
            let scrollPer = (share * 0.755) + "%"
            setOffset(scrollPer);
        }

        slideRef.current.addEventListener("scroll", handleScroll);

        slideRef.current.addEventListener('mousedown', e => {
            slideRef.current.isDown = true;
            slideRef.current.classList.add('active');
            slideRef.current.startX = e.pageX - slideRef.current.offsetLeft;
            slideRef.current.scrollLeftValue = slideRef.current.scrollLeft;
        });
        slideRef.current.addEventListener('mouseleave', () => {
            slideRef.current.isDown = false;
            slideRef.current.classList.remove('active');
        });
        slideRef.current.addEventListener('mouseup', () => {
            slideRef.current.isDown = false;
            slideRef.current.classList.remove('active');
        });
        slideRef.current.addEventListener('mousemove', e => {
            if(!slideRef.current.isDown){
                return;
            }
            e.preventDefault();
            const x = e.pageX - slideRef.current.offsetLeft;
            const walk = x - slideRef.current.startX;
            slideRef.current.scrollLeft = slideRef.current.scrollLeftValue - walk;
        });

        
        return () => {
            
            if (slideRef && slideRef.current) {
                slideRef.current.removeEventListener("scroll", handleScroll);
            }
        }
    },[]);

    return (
        <SlideBarWrapper $mobileSlider={mobileSlider}>
            <SlideLine>
                <SlideBarIndicator $position={offset}></SlideBarIndicator>
            </SlideLine>
        </SlideBarWrapper>
    )
}

export default SliderBar;