import React from "react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import BlokImage from "components/BlokImage";
import SbEditable from 'storyblok-react';

const Item = styled("div", props => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "42px",
    position: (props.$index + 1) % 4 === 3 ? "relative" : "static",
    marginTop: (props.$index + 1) % 4 === 0 ? "1.675rem" : "0",
    alignItems: (props.$index + 1) % 2 === 0 ? "flex-end" : "flex-start",

    [media.min.tablet]: {
        // margin: "0",
        // alignSelf: "auto",
        gridColumnStart: 
        props.$index === 0 ? "1" : 
        props.$index === 1 ? "25" :
        props.$index === 2 ? "4" :
        props.$index === 3 ? "22" :
        null,
        gridColumnEnd: 
        props.$index === 0 ? "20" : 
        props.$index === 1 ? "38" :
        props.$index === 2 ? "17" :
        props.$index === 3 ? "41" :
        null,
        gridRowStart: 
        props.$index === 0 ? "1" : 
        props.$index === 1 ? "1" :
        props.$index === 2 ? "10" :
        props.$index === 3 ? "12" :
        null,
        gridRowEnd: 
        props.$index === 0 ? "8" : 
        props.$index === 1 ? "10" :
        props.$index === 2 ? "19" :
        props.$index === 3 ? "19" :
        null,
    },
}));

const ItemContainer = styled("div", {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    height: "100%",
    [media.min.tablet]: {
        width: "100%",
    }
});


const Tag = styled("p", props => ({
    fontSize: "15px",
    lineHeight: "19px",
    marginTop: "0.5rem",
    textTransform: "uppercase",
    [media.min.laptop]: {
        fontSize: "1rem",
        lineHeight: "20.8px",
        marginTop: "1rem"
    }
}));

const Description = styled("p", props => ({
    fontSize: "15px",
    lineHeight: "19px",
    [media.min.laptop]: {
        fontSize: "1rem",
        lineHeight: "20.8px",
    }
}));

const TextWrapper = styled("div", props => ({
    maxWidth: "fit-content"
}));

const Image  = styled("div", props => ({
    position: "relative",
    display: "block",
    height: props.$index % 3 === 0 ? "194px" : "368px",
    width: props.$index % 3 === 0 ? "296px" : "297px",
    [media.min.tablet]: {
        width: "100%",
        height: "100%"
    }
}));


const GalleryItem = ({ blok, index }) => {
    return (
        <SbEditable content={blok}>
                <Item $index={index}>
                    <ItemContainer>
                        <Image $index={index} >
                            <BlokImage
                                wrapperStyle={{
                                    height: "100%",
                                    width: "100%",
                                    position: "relative",
                                    filter: "brightness(0.77)",
                                    top: "0",
                                    left: "0",
                                }}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                alt={blok.title}
                                fluid={{}}
                                blok={blok.media.filename}
                            />
                        </Image>
                        <TextWrapper>
                            <Tag>{blok.tag} //</Tag>
                            <Description>{blok.text}</Description>
                        </TextWrapper>
                    </ItemContainer>

                </Item>
        </SbEditable>
    )
}   

export default GalleryItem;