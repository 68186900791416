import React, { useState }  from "react";
import { styled } from 'libs/styletron';

const Item = styled("div", props => ({  
  padding: "0.75rem 0 0.5rem 0",
  fontSize: "1.25rem",
  borderBottom: "1px solid #000",
  alignItems: "center",
  overflow: "hidden"
}))

const Icon = styled("div", props => ({  
}))

const Button = styled("div", props => ({ 
  display: props.$layout === 'horizontal' ? 'block' : 'flex',
  cursor: "pointer"
}));


const Title = styled("div", props => ({ 
  width: '100%',
  lineHeight: "1"
}))

const Text = styled("div", props => ({ 
  height: props.$status === 'active' ? 'auto' : '0',
  transition: "height 200ms ease",
  overflow: "hidden"
}))

const TextInner = styled("p", props => ({ 
  padding: "2rem 0"
}))



const ListItem = ({ blok, layout }) => {

  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  function toggleAccordion() {
    
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : "200px"
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }


  return (
      <Item>
        <Button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <Title $layout={layout}>
            {blok.titel}
          </Title>
          <Icon $layout={layout}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="9.5" x2="19" y2="9.5" stroke="black"/>
              <line x1="9.5" y1="19.3333" x2="9.5" y2="0.333315" stroke="black"/>
            </svg>
          </Icon>
        </Button>
        <Text $status={setActive}>
          <TextInner>{blok.text}</TextInner>
        </Text>
      </Item>
  )
};

export default ListItem;