import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { colors } from "config/theme";
import MarqueeRow from "./MarqueeRow";

const Wrapper = styled("div", { 
  overflow: "hidden",
  borderTop: "1px solid " + colors.black
})


const Marquee = ({ blok }) => (
  <SbEditable content={blok}>
    <Wrapper>
      {blok.rows.map((blok, index) => (
          React.createElement(MarqueeRow, {
            key: blok._uid,
            blok: blok,
            index
          })
        )
      )}
    </Wrapper>
  </SbEditable>
);

export default Marquee;