import React from 'react';
import { styled } from 'libs/styletron';
import { media } from "config/theme";

const Overlay = styled('div', props => ({
    display: "inline-block",
    position: "fixed",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    zIndex: "9999",
    pointerEvents: props.$open ? 'auto' : 'none',
    backgroundColor: "rgba(0,0,0,0.3)",
    animationDuration: "0.5s",
    animationTimingFunction: "linear",
    opacity: props.$open ? '1' : '0',

    animationName: {
        from: {
          opacity: props.$open ? "0" : "1",
          visibility: props.$open ? "0" : "1"
        },
        to: {
            opacity: props.$open ? "1" : "0",
            visibility: props.$open ? "1" : "0"
        }
    }
}));

const ContentWrapper = styled('div', props => ({
    height: "100%",
    width: "100%",

    position: "fixed",
    top: "0",
    right: '0',
    overflowY: props.$open ? 'scroll' : 'inherit',
    zIndex: "10000",
    backgroundColor: "white",
    transition: "transform .5s ease",
    transform: props.$open ? 'translateX(0)' : "translateX(100%)",

    [media.min.ipad]: {
        width: props.$layout === "full" ? '100%' : '70%',
    },

    [media.min.laptop]: {
        width: props.$layout === "full" ? '100%' : '50.5%',
    }
}));

const InnerWrapper = styled('div', {
    position: "relative",
    padding: "24px",

    [media.min.laptop]: {
        padding: "32px",
    }

});


const TopBar = styled('div', {
    right: "1rem",
    top: "2rem",
    position: "absolute",
});

const CloseWrapper = styled('div', {
    position: "relative",
    margin: "0 0 28px auto",
    cursor: "pointer",
    pointerEvents: "auto",

    [media.min.ipad]: {
        display: "none"
    }
});

const Drawer = ({ open, onClick, layout, children }) => (
    <>
        <Overlay
            $open={open}
            onClick={onClick}
        />
        <ContentWrapper
            $open={open}
            $layout={layout}
        >
            <InnerWrapper>
                <TopBar>
                    <CloseWrapper onClick={onClick}>
                        <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="9.01557" y1="27.6932" x2="27.4711" y2="9.23769" stroke="black" strokeWidth="1.5"/>
                            <line x1="27.4711" y1="28.1175" x2="9.01559" y2="9.662" stroke="black" strokeWidth="1.5"/>
                        </svg>
                    </CloseWrapper>
                </TopBar>
                {children}
            </InnerWrapper>
        </ContentWrapper>
    </>
);

export default Drawer;
