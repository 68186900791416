import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

const MetaData = ({ seoMetaDescription, seoTitle, overrideTitle, seoOgimage, type, url }) => {
  const { site } = useStaticQuery(query)

  if (seoOgimage) {
    if (seoOgimage.startsWith('//')) {
      seoOgimage = 'https:' + seoOgimage
    }
  }
  const cannocial_url_part = url && (url === 'home' ? '' :  url  +  (url.endsWith("/") ? "" : "/" ));
  const canonical = site.siteMetadata.siteUrl + "/" + cannocial_url_part;
  const title = overrideTitle ? overrideTitle : (seoTitle ? seoTitle + ' - ' : '' ) + site.siteMetadata.title;

  return (
    <Helmet defer={false}>
      <html lang="sv" />

      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}
      {title && <meta property="og:image:alt" content={title} />}
      {title && <meta property="twitter:image:alt" content={title} />}

      {seoMetaDescription && (
        <meta name="description" content={seoMetaDescription} />
      )}
      {seoMetaDescription && (
        <meta name="og:description" content={seoMetaDescription} />
      )}

      {seoOgimage && <meta property="og:image" content={seoOgimage} />}
      {seoOgimage && <meta property="twitter:image:src" content={seoOgimage} />}

      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}

      {url && <meta property="og:url" content={canonical} />}
      {url && <meta property="twitter:url" content={canonical} />}
      {url && <link rel="canonical" href={canonical} />}
    </Helmet>
  )
}

export default MetaData;