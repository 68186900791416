import React, { useState } from 'react';
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import Components from "../../components.js";
import Button from '../../buttons/Button';

const Wrapper = styled('div', {
    background: "#000",
    display: "block",
    opacity: "1",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "10",
    
    [media.min.laptop]:  {
        display: "none"
    }
});

const Menu = styled('ul', {
    paddingTop: "1rem",
    marginTop: "3.4rem",
    listStyle: "list",
    borderTop: "1px solid #fff",
});

const Actions = styled('div', {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    borderTop: "1px solid #fff",
    display: "flex",
    alignItems: "center",
    padding: "1rem"
});

const ByLine = styled('div', {
    flex: "1"
});


const ContactButton = styled(Button, props => ({
    justifyContent: "flex-end",
    color: props.$toggle ? "#000!important" : "#fff!important",
    background: props.$toggle ? "#fff!important" : null,
    borderColor: props.$toggle ? "#000" : "#fff!important"
}));

const MobileMenu = ({items, onLink, btnClick, ...rest }) => {
    const [btnState, setBtnState] = useState(false)

    const contactClick = () => {
        setBtnState(!btnState);
        btnClick();
    }
 
    return (
        <Wrapper {...rest}>
            <Menu className="navbar-nav">
                {
                    items &&
                    items.map(blok =>
                        React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                        onLink
                    }))
                }
            </Menu>
            <Actions>
                <ByLine>By Grebban</ByLine>
                <ContactButton $toggle={btnState} onClick={() => contactClick()} theme="dark">Kontakta oss</ContactButton>
            </Actions>
        </Wrapper>
    );
};


export default MobileMenu;