import maxWidth from './max-width';

export const breakpoints = [375, 768, 1024, 1440, 1680, parseInt(maxWidth, 10)];
export const labels = ['mobile', 'tablet', 'laptop', 'desktop', 'desktopLarge', 'maxWidth'];

const min = breakpoints.reduce(
    (obj, value, i) => ({
        ...obj,
        [labels[i]]: `@media screen and (min-width: ${value}px)`,
    }),
    {}
);

const max = breakpoints.reduce(
    (obj, value, i) => ({
        ...obj,
        [labels[i]]: `@media screen and (max-width: ${value - 1}px)`,
    }),
    {}
);

const hover = '@media (hover: hover), (-ms-high-contrast: none)';
const isIE = '@media all and (-ms-high-contrast: none)';

export default {
    min,
    max,
    hover,
    isIE,
};