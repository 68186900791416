import React from "react";
import SbEditable from "storyblok-react";
import { styled } from 'libs/styletron';
import { media } from "config/theme";
import ImgItem from "./ImageItem";
import ListItem from "./ListItem";
import DefaultWrapper from "components/layouts/DefaultWrapper";
import Column from "components/layouts/Column";


const ContentWrapper = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    width: "100%",

    [media.min.tablet]: {
        width: "65%",
    },
    
    [media.min.laptop]: {
        alignSelf: "flex-end"
    }
});

const Title = styled("h2", {
  fontSize: "1.5625rem",

  [media.min.tablet]: {
    fontSize: "2rem",
    marginBottom: "4rem",
  },

  [media.min.laptop]: {
    fontSize: "1.875rem",
    marginTop: "0.5rem"
  },
  
  [media.min.maxWidth]: {
    marginTop: "1rem"
  }
});

const ImageWrapper = styled("div", {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(4, 1fr)",
    rowGap: "3.4rem",
    columnGap: "0",
    paddingTop: "4rem",
    width: "auto",
    height: "15rem",

    [media.min.tablet]: {
        gridTemplateColumns: "repeat(4, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        rowGap: "4.3rem",
        columnGap: "0%",
        paddingTop: "4rem",
        paddingBottom: "5.625rem",
        width: "120%",
        height: "8.5rem",
    },

    [media.min.laptop]: {
        columnGap: "10%",
        width: "auto"
    },

});

const List = styled("li", {
    display: "none",
    [media.min.tablet]: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridTemplateRows: "repeat(8, 1fr)",
        borderTop: "0.6px solid #000000",
    }
});


const Collaborations = ({ blok }) => (
  <SbEditable content={blok}>
    <DefaultWrapper layout="horizontal">
      <Column space="4">
        <Title>{blok.title}</Title>
      </Column>
      <Column>
        <ContentWrapper>
          <ImageWrapper>
              {blok.collab_img.map((blok, index) =>
                  React.createElement(ImgItem, {
                      key: blok._uid,
                      blok: blok,
                      index: index
                  })
              )}
          </ImageWrapper>
          <List>
              {blok.collab_text.map(blok =>
                  React.createElement(ListItem, {
                      key: blok._uid,
                      blok: blok,
                  })
              )}
          </List>
        </ContentWrapper>
      </Column>
    </DefaultWrapper>
  </SbEditable>
);

export default Collaborations;