const regExp = {
    getAllNumbers: /\d+/gm,
    getAllAlphabeticCharacters: /[a-z,[A-Z]+/gm,
    pageQuery: /\page=(.*)/,
    searchQuery: /\?s=(.*)/,
    filterQuery: /\?type=(.*)/,
    correctEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    empty: /^$/,
};

export default regExp;