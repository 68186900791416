import React from 'react'
import { styled } from 'libs/styletron';
import { media } from "config/theme";

const TopButton = styled('button', {
    cursor: "pointer",
    position: "absolute",
    right: "0.75rem",
    top: "0",

    [media.min.tablet]: {
        top: "unset",
        bottom: "2.5rem"
    },
  
    [media.min.laptop]: {
        right: "1rem",
    }
});

const handleClick = () => {
    if (typeof window !== `undefined`) {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }
}

const Scroll = () => {
    return (
        <TopButton onClick={handleClick} className="dark" aria-label="to top">
            <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5 12.8936L12.3272 1.72075L1.36704 12.6809" stroke="black" strokeWidth="1.5"/>
                <line x1="12.25" y1="2" x2="12.25" y2="28" stroke="black" strokeWidth="1.5"/>
            </svg>
        </TopButton>
    )
}

export default Scroll;